import React from 'react'
import Interweave from 'interweave'
import { Header, Head } from '../components'
import metaPages from './metapages'

import './Faq.scss'

function Faq(props) {
	return (
		<>
			<Head metaPage={props.metaPage} />
			<Header title='Veelgestelde vragen'/>
			<div className='layout layout--top layout--bottom'>
				{JSON.parse(metaPages.Faq.OGStructuredData).mainEntity.map((question, idx) => (
					<div className='faq__entry' id={`faq-${idx}`}>
						<h2 className='faq__entry--question'>{question.name}</h2>
						<p className='faq__entry--answer'><Interweave content={question.acceptedAnswer.text} /></p>
					</div>
				))}
			</div>
		</>
	);
}

export default Faq;
