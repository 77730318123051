import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { getStats } from '../../store'

import './Stats.scss'

const doneTasksBeforePlatform = 400

export function Component(props) {
  useEffect(() => {
    props.getStats()
  }, []);

  const renderStat = (title, count) => (
    <div className='stats__counters__item'>
      <div className="stats__counters__item__label">{title}</div>
      <div className="stats__counters__item__bar"/>
      <div className='stats__counters__item__count'>{count}</div>
    </div>
  )

  const users = props.stats?.users || 18326 // TODO: replace the fallback with zero once the counter is implemented in the API
  const openTasks = props.stats?.openTasks || 0
  const claimedTasks = props.stats?.claimedTasks || 0
  const doneTasks = (props.stats?.doneTasks || 0) + doneTasksBeforePlatform

  return (
    <div className='stats'>
      <h1>onze impact (daar hoor jij ook bij)</h1>
      <div className='stats__user-count'>
        <div className='stats__user-count__side-panel'>
          <p className='stats__user-count__side-panel__title'>100 mensen</p>
          <p className='stats__user-count__side-panel__description'>
            er zijn {users.toLocaleString('nl-NL')} aanmeldingen, dat zijn {Math.floor(users/100)} bolletjes!
          </p>
        </div>
        <div className="stats__user-count__bucket">
          {new Array(Math.floor(users/100)).fill(null).map((v, i) => (
            <div className="stats__user-count__bucket__item" key={'user-circle-' + i}/>
          ))}
        </div>
      </div>
      <div className='stats__counters'>
        {renderStat('openstaande hulpvragen', openTasks)}
        {renderStat('mensen die nu worden geholpen', claimedTasks)}
        {renderStat('mensen geholpen', doneTasks)}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  stats: state.application.stats
});

const mapDispatchToProps = { getStats };

export const Stats = connect(mapStateToProps, mapDispatchToProps)(Component)
