import { actions } from './actions'

const initialState = {
  myTasks: null,
  availableTasks: null,
  doneTasks: null,
  taskTypes: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.getTypes:
      return reduceGetTypes(state, action);
    case actions.getTasks:
      return reduceGetTasks(state, action);
    case actions.createTask:
      return reduceCreateTask(state, action);
    default:
      return state
  }
}

const reduceGetTypes = (state, action) => ({
  ...state,
  taskTypes: action.data
})

const reduceGetTasks = (state, action) => ({
  ...state,
  availableTasks: action.data.tasks,
  doneTasks: action.data.doneTasks
})


const reduceCreateTask = (state, action) => ({
  ...state,
  myTasks: Array.isArray(state.myTasks) ? state.myTasks.push(action.data) : [action.data]
})
