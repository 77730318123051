import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { useDidUpdateEffect } from '../helpers'
import { createTask } from '../store'
import { isValidPostalCode, cleanPostalCode } from '../utils'
import { Checkbox, Header, Head } from '../components'
import { selectStyles } from '../constants'
import { messages, MAX_LENGTH_DESCRIPTION } from '../constants'

import { ReactComponent as CheckmarkIcon } from '../assets/icons/checkmark.svg'
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg'

import './PostTask.scss'

function PostTask(props) {
  const taskTypeOptions = props.taskTypes.map((taskType) => ({
    value: taskType.id,
    label: `${taskType.icon} ${taskType.name}`
  }));

  const whenOptions = [
    {value: 'zsm', label: 'zo spoedig mogelijk'},
    {value: 'vandaag', label: 'vandaag'},
    {value: 'deze week', label: 'deze week'}
  ];

  const [firstName, setFirstName] = useState(props.user?.firstName || '');
  const [lastName, setLastName] = useState(props.user?.lastName || '');
  const [phoneNumber, setPhoneNumber] = useState(props.user?.phone || '');
  const [email, setEmail] = useState(props.user?.email || '');
  const [postalCode, setPostalCode] = useState(props.postalCode || '');
  const [type, setType] = useState('');
  const [when, setWhen] = useState('');
  const [description, setDescription] = useState('');
  const [charCountDescription, setCharCountDescription] = useState(0);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [postSentSuccessfully, setPostSentSuccessfully] = useState(false);

  useDidUpdateEffect(() => {
    setPostSentSuccessfully(true)
  }, [props.myTasks]);

  const handleChangeDescription = e => {
    const currentLength = (e.target.value || '').length;
    setCharCountDescription(currentLength);
    setDescription(e.target.value);
  };

  const postTask = (e) => {
    e.preventDefault();

    if (!agreedToTerms) {
      toast.error(messages.agreeToTerms)
      return
    }
    props.createTask(!!props.user, firstName, lastName, phoneNumber, email, postalCode, type.value, when.value, description)
  };

  if (postSentSuccessfully) {
    return (
      <Redirect to='/'/>
    )
  }

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='vraag hulp'/>
      <div className='layout layout--bottom layout--top'>
        <div className="post-task">
          <div className='container'>
            <p>Door het overweldigende success van dit platform hebben vele gewone mensen (helden) kunnen helpen in deze tijden van nood. Nu de intelligente lockdown regels versoepeld worden zijn ook de nood-hulpvragen af aan het nemen en is het online prikbord overbodig. Om misbruik te voorkomen is het onmogelijk om een nieuwe vraag te plaatsen. Mocht je ernstige hulp nodig hebben dat is gerelateerd aan de corona crisis kan je ons altijd e-mailen op <a href="mailto:support@gewoonmensen.nl"><u>support@gewoonmensen.nl</u></a> en dan kijken we of dat er iets is dat we kunnen doen voor je.</p>
            <p>Organisaties kunnen hulp aanvragen via <a href="https://docs.google.com/forms/d/e/1FAIpQLSe2kMdC_gyJFA9Jkmbit_iEQcYBrSZMwVA6HLZcURj95xOSKw/viewform?fbzx=5452407611054117258" target="_blank" rel="noopener noreferrer"><u>dit formulier</u></a>.</p>
          </div>
          <div className="post-task__guidance">
            <div className="post-task__guidance__content">
              <p>Heeft u hulp nodig bij het plaatsen van een hulpvraag? Bel dan <a href="tel:31851309656">085 1309 656</a>, dit nummer is dagelijks tussen 10.00 en 19.00 bereikbaar.<br/>
              De hulpvraag moet aan de volgende eisen voldoen:</p>
              <ul>
                <li>Een gevolg van het COVID-19 virus zijn</li>
                <li>Geen commerciële doeleinden hebben</li>
                <li>Een goed gedefinieerde vraag zijn</li>
                <li>In één actie afgesloten kunnen worden</li>
              </ul>
              <p>Bij vragen kan er altijd gemaild worden naar <a href="mailto:support@gewoonmensen.nl">support@gewoonmensen.nl</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  postalCode: state.application.postalCode,
  taskTypes: state.tasks.taskTypes,
  myTasks: state.tasks.myTasks,
  user: state.auth.user
});

const mapDispatchToProps = { createTask };

export default connect(mapStateToProps, mapDispatchToProps)(PostTask);
