import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import dotenv from 'dotenv'
import { configureStore } from './store'
import history from './components/History';

import App from './containers/App'

import '../node_modules/normalize.css/normalize.css'
import './fonts.scss'
import './global.scss'
import './react-select.scss'

dotenv.config();

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN})
}

export const { store, persistor } = configureStore();

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <App/>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
};

ReactDOM.render(<Root/>, document.getElementById('root'));
