import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { api } from '../utils'
import classnames from 'classnames'
import { Redirect } from 'react-router-dom'

import { ReactComponent as ClockIcon } from '../assets/icons/clock.svg';
import { ReactComponent as WhatsAppIcon } from '../assets/brands/WhatsApp.svg';
import { ReactComponent as FacebookIcon } from '../assets/brands/Facebook.svg'
import { ReactComponent as VerifiedIcon } from '../assets/icons/verified.svg';
import { ReactComponent as CopyIcon } from '../assets/icons/copy.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';


import { getPendingTasks, getCompletedTasks } from '../store'
import { toast } from 'react-toastify'
import { messages } from '../constants'
import { BASE_URL_FRONT_END } from "../helpers/const";

import './TaskCard.scss'

function Component(props) {
  const [taskClaimedSuccesfully, setTaskClaimedSuccesfully] = useState(false);
  const [clicked, isClicked] = useState(false);

  const couldPhoneNumberBeWhatsapp = (phoneNumber) => (
    phoneNumber.substr(0,2) === '06'
    || phoneNumber.substr(0,3) === '+31'
  );

  const getFormattedPhoneNumber = (phoneNumber) => {
    if(phoneNumber.substr(0,2) === '06'){
      phoneNumber = '316'+phoneNumber.substr(2, phoneNumber.length)
    }
    return phoneNumber.replace('06', '+316')
      .replace(' ', '')
      .replace('+316', '316');
  }

  const updateTaskStatus = async newStatus => {
    try {

      const res = await api.post(`/task/${props.id}/status/update/`, {newStatus});
      return res
    } catch(e){
      if(e.response.data.error === 'Task is already done, confirmed or deleted.'){
        toast.error(messages.taskAlreadyClaimed)
        return false;
      }

      toast.error(messages.somethingWentWrong)
      return false;
    }
  };

  const completeTask = (e) => {
    updateTaskStatus('done').then((completed) => {
      if(completed){
        toast.success(messages.taskCompleted)
        fetchTasks()
      }
    })
  };

  const releaseTask = (e) => {
    updateTaskStatus('open').then((relased) => {
      if(relased){
        toast.success(messages.taskReleased)
        fetchTasks()
      }
    })
  };

  const claimTask = (e) => {
    updateTaskStatus('claimed').then((claimed) => {
      if(claimed){
        toast.success(messages.taskClaimed)
        fetchTasks()
        setTaskClaimedSuccesfully(true)
      }
    })
  };

  const clickHandler = (e) => {
    if(props.user){
      isClicked(clicked => !clicked)
    }
  };

  const fetchTasks = () => {
    props.getPendingTasks();
    props.getCompletedTasks();
  };

  const whatsAppMessage = `Ik%20zag%20deze%20hulp%20vraag%20op%20gewoonmensen.nl%20en%20ik%20dacht%20dat%20jij%20wel%20zou%20kunnen%20helpen!%20Klik%20op%20deze%20link%20om%20de%20hulpvraag%20te%20bekijken:%20${BASE_URL_FRONT_END}/ik-wil-helpen/${props.id}`
  const FacebookMessage = `Ik zag deze hulp vraag op gewoonmensen.nl. Wie zou kunnen helpen? Klik op deze link om de hulpvraag te bekijken: ${BASE_URL_FRONT_END}/ik-wil-helpen/${props.id}`;

  function copyToClipboard(link) {

    let input = document.createElement('input');

    input.setAttribute('value', link);
    document.body.appendChild(input);
    input.select();

    let result = document.execCommand('copy');
    document.body.removeChild(input);

    toast.success(messages.copiedToClipboardSuccessfully);
    return result;
  };

  if (taskClaimedSuccesfully) {
    return (
      <Redirect to='/profiel'/>
    )
  };

  return (
    <div className='task-card'>
      <div className='task-card__content'>
        <div className='task-card__content__header'>
          <span className='task-card__content__header__type'>{props.title}</span>
          <span className='task-card__content__header__location'>
            {props.locationTooltip && <span className="task-card__content__header__location__tooltip">{props.locationTooltip}</span>}
            {props.location}
            </span>
          {props.verified &&
            <span className='task-card__content__header__verified'>
              <span className="task-card__content__header__verified__tooltip">Geverifieerd</span>
                <VerifiedIcon/>
              </span>}
        </div>
        <p>{props.description}</p>
        <div className='task-card__content__footer'>
          <ClockIcon/>
          <div className='task-card__content__footer--urgency'>
            {props.urgency}
          </div>
        </div>
      </div>
      <div className='task-card__cta'>
        <div className='task-card__cta--contact'>
          {!props.phoneNumber && !clicked &&
          <>
            <div className='task-card__cta__desktop'>
              <a className='task-card__cta__share'>
              <ShareIcon/>
              </a>
              <div className='task-card__cta__desktop--tooltip'>
              <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`https://web.whatsapp.com/send?text=${whatsAppMessage}`}
                  className='task-card__cta__share--inner'
              >
                  <WhatsAppIcon/>
              </a>
              <a
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() =>{window.FB.ui({
                    display: 'popup',
                    method: 'share',
                    quote: FacebookMessage,
                    href: `${BASE_URL_FRONT_END}/ik-wil-helpen/${props.id}`,
                  }, function(response){})}}
                  className='task-card__cta__share--inner'
              >
                <FacebookIcon/>
              </a>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => copyToClipboard(`${BASE_URL_FRONT_END}/ik-wil-helpen/${props.id}`)}
                    className='task-card__cta__share--inner'
                >
                  <CopyIcon/>
                </a>
              </div>
            </div>
              <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`whatsapp://send?text=${whatsAppMessage}`}
                  className='task-card__cta__whatsapp task-card__cta__mobile'
              >
                <WhatsAppIcon/>
                <span>deel dit!</span>
              </a>
            </>}
          {props.status === 'confirmed' && (
            <>
              Bevestigd door opdrachtgever!
            </>
          )}
          {props.phoneNumber && (
            <>
              <a href={`tel:${props.phoneNumber}`}>{props.phoneNumber}</a>
              {couldPhoneNumberBeWhatsapp(props.phoneNumber) && (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`https://wa.me/${getFormattedPhoneNumber(props.phoneNumber)}`}
                  className='task-card__cta__whatsapp'
                >
                  <WhatsAppIcon/>
                  <span>whatsapp</span>
                </a>
              )}
            </>
          )}
        </div>
        <div>
          {props.canComplete && <div className='task-card__cta__button task-card__cta__button--green' onClick={completeTask}>afgerond</div>}
          {props.canRelease && <div className='task-card__cta__button task-card__cta__button--red' onClick={releaseTask}>plaats terug</div>}
          {!props.user && props.canClaim && <Link to='/registreer' className='task-card__cta__login'>Je moet een account aanmaken voordat je kunt helpen</Link>}
          {props.user && clicked &&
            <div>
              <p>
                Top dat je wilt helpen!<br/>
                We hebben een paar spelregels:
              </p>
                <ul>
                  <li>Neem binnen 1 uur contact op</li>
                  <li>Probeer op een normaal tijdstip te bellen, een goede nachtrust is extra belangrijk in deze tijden.</li>
                </ul>
              <p>
                Zodra je op “ja” klikt kan niemand anders dit hulpverzoek meer zien. Doe dat dus alleen als je 100% zeker bent dat je wil en kan helpen.
              </p>
              <div className='task-card__cta__button-container'>
                <div className='task-card__cta__button task-card__cta__button-container--button' id='fb-pixel-claim-help-request' onClick={claimTask}>Ja</div>
                <div className='task-card__cta__button task-card__cta__button-container--button' onClick={clickHandler}>Nee</div>
              </div>
            </div>}
          {props.canClaim && !clicked && <div className={classnames('task-card__cta__button task-card__cta__button--blue', {
            'task-card__cta__button task-card__cta__button--unavailable': !props.user
          })} onClick={clickHandler}>ik help</div>}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  getPendingTasks,
  getCompletedTasks
};

export const TaskCard = connect(mapStateToProps, mapDispatchToProps)(Component);
