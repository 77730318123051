import axios from 'axios'
import { store } from '../index'

const defaultTransformers = () => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) {
    return [];
  } else if (transformRequest instanceof Array) {
    return transformRequest;
  } else {
    return [transformRequest];
  }
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
  transformRequest: [
    ...defaultTransformers(),
    (data, headers) => {
      if (store.getState().auth.user) {
        headers['Authorization'] = `Bearer ${store.getState().auth.user.accessToken}`;
      }
      return data
    }
  ]
});
