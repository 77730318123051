import React from 'react'

import { Header, Head } from '../components'

import './AboutUs.scss'

function AboutUs(props) {
	return (
		<>
			<Head metaPage={props.metaPage} />
			<Header title='Over ons'/>
			<div className='layout layout--top layout--bottom'>
				<div className='aboutus__container'>
					<div className='aboutus__container--section1'>
						<h2>Wie zijn wij?</h2>
						<p>Wij zijn gewone mensen net zoals jij die een online prikbord hebben gemaakt. In deze barre tijden van de pandemie en de daaruitvloeiende 1,5m economie moeten mensen in een hoog-risico groep extra uitkijken. Vooral die groep hebben vrijwilligers nodig om hen een handje te helpen met de dagelijkse dingen zoals boodschappen doen, de hond uitlaten, of wellicht voor het maken van een praatje voor het sociale contact. Op dit online prikbord kunnen hulpbehoevenden dus een oproepje plaatsen zodat gewone lieve mensen kunnen helpen.</p>

						<img className='aboutus__container--img' src='/img/gewoonmensen.jpeg' alt='Bestuur van Stichting GewoonMensen' />
					</div>
					<div className='aboutus__container--section2'>
						<h2>Stichting informatie</h2>
						<h3>Fiscaal</h3>
						<p>RSIN: 861103105</p>
						<h3>Bestuur</h3>
						<ul>
							<li>Naut van Teeseling - Voorzitter</li>
							<li>Amber Nagelhout - Penningmeester</li>
							<li>Thijs de Jongh - Secretaris</li>
							<li>Jasper Veen - Bestuurder</li>
						</ul>

						<h3>Financiën</h3>
						<p>Stichting gewoonmensen heeft geen geldstromen en keert dan ook geen beloningen uit aan haar bestuurders of vrijwilligers.</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default AboutUs;
