/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { getTaskTypes } from '../store'
import { Nav, Footer } from './lib'

import Login from './auth/Login'
import Preregister from './auth/Preregister'
import Register from './auth/Register'
import ForgotPassword from './auth/ForgotPassword'
import ResetPassword from './auth/ResetPassword'

import ConfirmTask from './ConfirmTask'
import ConfirmContactedTask from './ConfirmContactedTask'
import DeleteTask from './DeleteTask'
import Unsubscribe from './Unsubscribe'
import Dashboard from './Dashboard'
import EditTask from './EditTask'
import Organizations from './Organizations'
import PostTask from './PostTask'
import Profile from './Profile'
import ReopenTask from './ReopenTask'
import TaskOverview from './TaskOverview'
import Terms from './Terms'
import SingleTask from './SingleTask'
import NotFound from './NotFound'
import ConfirmClaimTask from './ConfirmClaimTask'
import Faq from './Faq'
import AboutUs from './AboutUs'
import Gedoneerd from './Gedoneerd'

import metaPages from './metapages'

import 'react-toastify/dist/ReactToastify.css'

const BANNER_CONFIG = {
  show: false,
  routes: ['/'],
}

function App(props) {
  const location = useLocation()

  useEffect(() => {
    const fetchTaskTypes = async () => {
      if (props.taskTypes && props.taskTypes.length === 0) {
        props.getTaskTypes()
      }
    };
    fetchTaskTypes()
  }, []);

  return (
    <>
      <Nav transparent={location.pathname === '/' || location.pathname.includes('/bevrijdingsdag')} banner={BANNER_CONFIG}/>
      <main style={{marginTop: BANNER_CONFIG.show && BANNER_CONFIG.routes.includes(location.pathname) ? '2.5rem' : ''}}>
        <Switch>
					<Route exact path='/login' render={(props) => <Login {...props} metaPage={metaPages.Login}/>}/>
					<Route exact path='/registreer' render={(props) => <Register {...props} metaPage={metaPages.Register}/>}/>
					<Route exact path='/wachtwoord-vergeten' render={(props) => <ForgotPassword {...props} metaPage={metaPages.ForgotPassword}/>}/>
					<Route exact path='/auth/reset-password/:passwordToken' render={(props) => <ResetPassword {...props} metaPage={metaPages.ResetPassword}/>}/>
					<Route exact path='/auth/ongewoon/register/:uuid' render={(props) => <Preregister {...props} metaPage={metaPages.Preregister}/>}/>
					<Route exact path='/delete-task/:uuid/:hash' render={(props) => <DeleteTask {...props} metaPage={metaPages.DeleteTask}/>}/>
					<Route exact path='/unsubscribe/:id/:hash' render={(props) => <Unsubscribe {...props} metaPage={metaPages.Unsubscribe}/>}/>
					<Route exact path='/edit-task/:uuid/:hash' render={(props) => <EditTask {...props} metaPage={metaPages.EditTask}/>}/>
					<Route exact path='/confirm-task/:uuid/:id?/:hash' render={(props) => <ConfirmTask {...props} metaPage={metaPages.ConfirmTask}/>}/>
          <Route exact path='/reopen-task/:uuid/:id?/:hash' render={(props) => <ReopenTask {...props} metaPage={metaPages.ReopenTask}/>}/>
					<Route exact path='/confirm-contacted/:uuid/:hash' render={(props) => <ConfirmContactedTask {...props} metaPage={metaPages.ConfirmContactedTask}/>}/>
					<Route exact path='/vraag-hulp' render={(props) => <PostTask {...props} metaPage={metaPages.PostTask}/>}/>
					<Route exact path='/ik-wil-helpen' render={(props) => <TaskOverview {...props} metaPage={metaPages.TaskOverview}/>}/>
          <Route exact path='/ik-wil-helpen/:uuid' render={(props) => <SingleTask {...props} metaPage={metaPages.SingleTask}/>}/>
          <Route exact path='/ik-wil-helpen/:uuid/:id/:hash' render={(props) => <ConfirmClaimTask {...props} metaPage={metaPages.ConfirmClaimTask}/>}/>
					<Route exact path='/profiel' render={(props) => <Profile {...props} metaPage={metaPages.Profile}/>}/>

					<Route exact path='/algemene-voorwaarden' render={(props) => <Terms {...props} metaPage={metaPages.Terms}/>}/>
					<Route exact path='/organisaties' render={(props) => <Organizations {...props} metaPage={metaPages.Organizations}/>}/>
					<Route exact path='/faq' render={(props) => <Faq {...props} metaPage={metaPages.Faq}/>}/>
					<Route exact path='/over-ons' render={(props) => <AboutUs {...props} metaPage={metaPages.AboutUs}/>}/>
          <Route exact path='/gedoneerd' render={(props) => <Gedoneerd {...props} metaPage={metaPages.Gedoneerd}/>}/>

					<Route exact path='/' render={(props) => <Dashboard {...props} metaPage={metaPages.Dashboard}/>}/>
					<Route path="/burenbriefje" component={() => {
						global.window && (global.window.location.href = 'https://drive.google.com/drive/folders/1wB6tgJ95x-eD2dSbeejo0g98A1C61J8O');
						return null;
					}}/>
					<Route render={(props) => <NotFound {...props} metaPage={metaPages.NotFound}/>}/>
        </Switch>
      </main>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Footer/>
    </>
  );
}

const mapStateToProps = state => ({
  taskTypes: state.tasks.taskTypes
});

const mapDispatchToProps = {getTaskTypes};

export default connect(mapStateToProps, mapDispatchToProps)(App);
