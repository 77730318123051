import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { api } from '../utils'
import { messages } from '../constants'
import { Header, Head } from '../components'

function ConfirmTask(props) {
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (!confirmed) {
      const confirmTask = async () => {
        try {
          let apiData = {}
          if (props.match.params.id) { // if helper called it from within email, can only move it to done
            apiData.url = `/task/${props.match.params.uuid}/status/update/${props.match.params.hash}/${props.match.params.id}`
            apiData.options = { newStatus: 'done' }
          } else {
            apiData.url = `/task/${props.match.params.uuid}/status/update/${props.match.params.hash}`
            apiData.options = { newStatus: 'confirmed' }
          }
          await api.post(apiData.url, apiData.options)
          setConfirmed(true)
        } catch (e) {
          toast.error(e.response.data.error || messages.somethingWentWrong)
        }
      };
      confirmTask()
    }
  }, [confirmed, props.match.params.uuid, props.match.params.hash]);

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title={confirmed ? 'Je hebt de hulpaanvraag succesvol afgerond!' : 'Bezig met bevestigen'}/>
      <div className='layout layout--bottom layout--top'>
        <div className='container'>
          <a href='/'>Keer terug naar de homepagina</a>
        </div>
      </div>
    </>
  );
}

export default ConfirmTask;
