import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import { api } from '../utils'
import { messages, selectStyles, MAX_LENGTH_DESCRIPTION } from '../constants'
import { Header, Head } from '../components'

import './EditTask.scss'


function EditTask(props) {
  const [task, setTask] = useState();
  const [description, setDescription] = useState('');
  const [when, setWhen] = useState('');
  const [charCountDescription, setCharCountDescription] = useState(0);
  const [taskDone, setTaskDone] = useState(false);

  const handleChangeDescription = e => {
    const currentLength = (e.target.value || '').length;
    setCharCountDescription(currentLength);
    setDescription(e.target.value);
  };

  const whenOptions = [
    {value: 'zsm', label: 'zo spoedig mogelijk'},
    {value: 'vandaag', label: 'vandaag'},
    {value: 'deze week', label: 'deze week'}
  ];

  const handleUpdate = async () => {
    try {
      await api.post(
        `/task/${props.match.params.uuid}/update/${props.match.params.hash}`,
        { newWhen: when.value, newDescription: description }
      );
      toast.success(messages.taskEditedSuccessfully);
    } catch (e) {
      toast.error(e.response.data.error || messages.somethingWentWrong);
      setTaskDone(true);
    }
  }

  const handleDelete = () => {
    confirmAlert({
      title: 'Aanvraag verwijderen',
      message: 'Weet je zeker dat je je aanvraag wilt verwijderen?',
      buttons: [
        {
          label: 'Verwijderen',
          onClick: () => confirmDelete()
        },
        {
          label: 'Annuleren'
        }
      ]
    });
  }

  const confirmDelete = async () => {
    try {
      await api.post(`/task/${props.match.params.uuid}/status/update/${props.match.params.hash}`, { newStatus: 'archived' })
      toast.success(messages.taskDeletedSuccessfully);
      setTaskDone(true);
    } catch (e) {
      toast.error(e.response.data.error || messages.somethingWentWrong)
    }
  }

  useEffect(() => {
    const getTask = async () => {
      try {
        api.get(`/task/${props.match.params.uuid}/${props.match.params.hash}`).then((res) => {
          const data = res.data.data;
          switch(data.status) {
            case 'archived':
              toast.error(messages.taskNotAvailable('verwijderd'));
              setTaskDone(true);
              break;
            case 'confirmed':
              toast.error(messages.taskNotAvailable('afgerond'));
              setTaskDone(true);
              break;
            default:
              setDescription(data.description);
              setCharCountDescription(data.description.length);
              setWhen(whenOptions.find(x => x.value === data.when))
              setTask(true);
              break;
          }
        }).catch((e) =>
          toast.error(e.response.data.error || messages.somethingWentWrong)
        )
      } catch (e) {
        toast.error(e.response.data.error || messages.somethingWentWrong)
      }
    };
    getTask()
  }, [props.match.params.uuid, props.match.params.hash]);

  if (taskDone) {
    return (
      <Redirect to='/'/>
    )
  }

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='aanvraag aanpassen'/>
      <div className='layout layout--bottom layout--top'>
        <div className="edit-task">
          <div className='container'>
            {!task ? (
              <p>Aan het laden</p>
            ) : (
              <>
                <p>Hier kan je je hulpverzoek aanpassen of verwijderen. </p>
                <div className='form-segment form-segment--text'>
                  <div className='form-segment form-segment--text'>
                    <label>wanneer</label>
                    <Select
                      id='when'
                      value={when}
                      placeholder='wanneer'
                      onChange={setWhen}
                      className='select__container'
                      classNamePrefix='select'
                      isSearchable={false}
                      styles={selectStyles}
                      options={whenOptions}
                    />
                  </div>
                  <label htmlFor='description'>omschrijving</label>
                  <div>
                    <textarea
                      id='description'
                      name='description'
                      maxLength={MAX_LENGTH_DESCRIPTION}
                      defaultValue={description}
                      onChange={e => handleChangeDescription(e)}
                    />
                    <span>{charCountDescription}/{MAX_LENGTH_DESCRIPTION}</span>
                  </div>
                </div>

                <div className="edit-task__button-container">
                  <button className='edit-task__button edit-task__button--green' onClick={handleUpdate}>Aanpassen</button>
                  <button className='edit-task__button edit-task__button--red' onClick={handleDelete}>Verwijderen</button>
                </div>
              </>
            )}

          </div>
        </div>
      </div>
    </>
  );
}

export default EditTask;
