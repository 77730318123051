import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { postSignup } from '../../store'
import { Checkbox, Header, Head } from '../../components'
import { messages } from '../../constants'
import { isValidPostalCode, cleanPostalCode } from '../../utils'

import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg'

function Register(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [postalCode, setPostalCode] = useState(props.postalCode || '');
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const register = (e) => {
    e.preventDefault();

    if (password !== passwordRepeat) {
      toast.error(messages.repeatPassword)
      return;
    }

    if (!agreedToTerms) {
      toast.error(messages.agreeToTerms)
      return;
    }

    props.postSignup(firstName, lastName, email, phoneNumber, password, postalCode);
  };

  if (props.user) {
    return (
      <Redirect to='/'/>
    )
  }

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='registreer'/>
      <div className='layout layout--top layout--bottom'>
        <div className='container'>
          <form onSubmit={register}>
            <div className='form-segment form-segment--text'>
            <label htmlFor='first-name'>voornaam</label>
            <input
              id='first-name'
              name='first-name'
              type='text'
              autoComplete='given-name'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
            <div className='form-segment form-segment--text'>
              <label htmlFor='last-name'>achternaam</label>
              <input
                id='last-name'
                name='last-name'
                type='text'
                autoComplete='family-name'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </div>
            <div className='form-segment form-segment--text'>
              <label htmlFor='email'>email</label>
              <input
                id='email'
                name='email'
                type='text'
                autoComplete='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='form-segment form-segment--text'>
              <label htmlFor='phone-number'>telefoonnummer</label>
              <input
                id='phone-number'
                name='phone-number'
                type='text'
                autoComplete='tel'
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className='form-segment form-segment--text'>
              <label htmlFor='postal-code'>postcode</label>
              <input
                id='postal-code'
                name='postal-code'
                type='text'
                autoComplete='postal-code'
                placeholder='1234AB'
                value={postalCode}
                className='extra-spacing'
                onChange={e => setPostalCode(cleanPostalCode(e.target.value))}
              />
              {postalCode && (isValidPostalCode(postalCode)
                  ? <div className='form-segment--text__icon form-segment--text__icon--positive'><CheckmarkIcon/></div>
                  : <div className='form-segment--text__icon form-segment--text__icon--warning'><WarningIcon/></div>
              )}
            </div>
            <div className='form-segment form-segment--text'>
              <label htmlFor='password'>wachtwoord</label>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='new-password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className='form-segment form-segment--text'>
              <label htmlFor='password-repeat'>wachtwoord (herhalen)</label>
              <input
                id='password-repeat'
                name='password-repeat'
                type='password'
                autoComplete='new-password'
                value={passwordRepeat}
                onChange={e => setPasswordRepeat(e.target.value)}
              />
            </div>
            <div className='form-segment'>
              <Checkbox
                id='terms'
                name='terms'
                type='checkbox'
                value={agreedToTerms}
                onChange={e => setAgreedToTerms(e.target.checked)}
                label={<label>Ik ga akkoord met de <a href="/algemene-voorwaarden" target="_blank"><u>algemene voorwaarden</u></a>.</label>}
              />
            </div>
            <input type='submit' value='registreren'/>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = { postSignup };

export default connect(mapStateToProps, mapDispatchToProps)(Register);
