import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import { TaskCard, Loader, Head } from '../components'
import { setPostalCode as setPostalCodeRedux, getTasks } from '../store'

import { ReactComponent as TaskOverviewIllustration } from '../assets/illustrations/task-overview.svg'

import './TaskOverview.scss'

function SingleTask(props) {
    const [loading, setLoading] = useState(true);

    const fetchTasks = () => {
        props.getTasks();
    };

    const singleTask = props.availableTasks && props.availableTasks.filter((availableTask) => availableTask.id === `${props.match.params.uuid}`);

    useEffect(() => {
        fetchTasks()
        setLoading(false);
    }, []);


    useEffect(() => {
        setLoading(false);
    }, [props.availableTasks]);

    return (
        <>
        <Head metaPage={props.metaPage} />
      <div className='layout layout--bottom layout--top'>
        <div className='task-overview'>
            <div className='container'>
                <div className='task-overview__task-list'>
                    {loading && <Loader/>}
                    {!loading && singleTask && (
                        singleTask.length === 0
                            ? (
                                <>
                                    <h1>De hulp vraag is al geholpen!</h1>
                                    <p>Kijk vooral of er nog andere mensen hulp nodig hebben!</p>
                                    <Link className='button task-overview__button' to='/ik-wil-helpen'>ik wil helpen</Link>
                                </>
                            )
                            :
                            singleTask
                            .map((availableTask) => {
                            let location = `${availableTask.zipcode} - ${availableTask.city}`;
                            let locationTooltip;
                            if(availableTask.distance){
                                let distance = `${availableTask.distance>1?availableTask.distance:'<1'}km`;
                                location = `${distance} - ${availableTask.city}`;
                                locationTooltip = availableTask.zipcode;
                            }

                            return (
                                <TaskCard
                                    onMutate={() => fetchTasks()}
                                    id={availableTask.id}
                                    title={`${availableTask.TaskType.icon} ${availableTask.TaskType.name}`}
                                    location={location}
                                    locationTooltip={locationTooltip}
                                    description={availableTask.description}
                                    urgency={availableTask.when}
                                    verified={availableTask.verified}
                                    canClaim
                                />
                            )}))
                            }
                </div>
            </div>
            <div className='task-overview__illustration'>
                <div className='task-overview__illustration__content'>
                    <h1>Hoe kan ik helpen?</h1>
                    <ol>
                        <li>Filter de hulpvraag</li>
                        <li>Klik op 'ik help' bij een aanvraag waar je denkt te kunnen helpen.</li>
                        <li>Neem contact op met het telefoonnummer dat verschijnt.</li>
                        <li>Kun je toch niet helpen? Klik dan op 'zet terug'.</li>
                        <li>Kun je hulp bieden? Super! Klik op 'afronden' om de hulpvraag definitief van de lijst te halen.</li>
                    </ol>
                    <h1>Bedankt voor je inzet!</h1>
                    <TaskOverviewIllustration/>
                </div>
            </div>
        </div>
      </div>
      </>
    );
}

const mapStateToProps = state => ({
    taskTypes: state.tasks.taskTypes,
    availableTasks: state.tasks.availableTasks,
    postalCode: state.application.postalCode,
});

const mapDispatchToProps = {
    setPostalCodeRedux,
    getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleTask);
