import { api } from '../../utils'

export const actions = {
  getPendingTasks: '@@me/tasks/pending',
  getCompletedTasks: '@@me/tasks/completed',
}

export function getPendingTasks() {
  return (dispatch) => {
    api.get('/me/claimed-tasks')
      .then((res) => {
        dispatch({
          type: actions.getPendingTasks,
          data: res.data.data
        })
      })
      .catch((e) => console.log(e))
  }
}

export function getCompletedTasks() {
  return (dispatch) => {
    api.get('/me/done-tasks')
      .then((res) => {
          dispatch({
          type: actions.getCompletedTasks,
          data: res.data.data
        })
      })
      .catch((e) => console.log(e))
  }
}
