import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Header, Head } from '../components'
import { api } from '../utils'
import { messages } from '../constants'

function ConfirmContactedTask(props) {
  const [contactedByHelperUpdated, setContactedByHelper] = useState(false);

  useEffect(() => {
    if (!contactedByHelperUpdated) {
      const confirmContactedByHelperTask = async () => {
        try {
          await api.post(`/task/${props.match.params.uuid}/contacted-by-helper/update/${props.match.params.hash}`)
          setContactedByHelper(true)
        } catch (e) {
          toast.error(e.response.data.error || messages.somethingWentWrong)
        }
      };
      confirmContactedByHelperTask()
    }
  }, [contactedByHelperUpdated, props.match.params.uuid, props.match.params.hash]);

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title={contactedByHelperUpdated ? 'Dankjewel voor het doorgeven dat er contact opgenomen is!' : 'Bezig...'}/>
      <div className='layout layout--bottom layout--top'>
        <div className='container'>
          <a href='/'>Keer terug naar de homepagina</a>
        </div>
      </div>
    </>
  );
}

export default ConfirmContactedTask;
