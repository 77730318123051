import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { api } from '../utils'
import { Loader, Checkbox, TaskCard, Header, Head } from '../components'
import { getPendingTasks, getCompletedTasks, getProfile } from '../store'
import { messages } from '../constants'

import './Profile.scss'

function Profile(props) {
  const fetchTasks = () => {
    props.getPendingTasks();
    props.getCompletedTasks();
  };

  useEffect(() => {
    fetchTasks()
  }, []);

  const toggleSubscribeDigest = async (checkEvent) => {
    try {
      await api.post('/auth/profile', { proximityDigestSubscribe: checkEvent });
      await props.getProfile();
      toast.success(messages.subscribeToggleSuccessfully);
    } catch (err) {
      toast.error(err.response.data.error || messages.somethingWentWrong);
    }
  }

  if (!props.user) {
    return <Redirect to='/'/>
  }

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title={props.user.firstName
        ? `Hey ${props.user.firstName}!`
        : 'Hallo!'
      }/>
      <div className='layout layout--bottom layout--top'>
        <div className='profile container'>
          <div className='profile__feed'>
            <div className='profile__feed__segment'>
              <h2>openstaande hulpverzoeken</h2>
              {!props.pendingTasks ? <Loader/> : (props.pendingTasks.length === 0
                ? (
                  <p>Je hebt geen openstaande hulpverzoeken</p>
                ) : props.pendingTasks.map((pendingTask) => (
                  <TaskCard
                    key={pendingTask.id}
                    onMutate={fetchTasks}
                    id={pendingTask.id}
                    title={`${pendingTask.TaskType.icon} ${pendingTask.TaskType.name}`}
                    location={pendingTask.zipcode}
                    description={pendingTask.description}
                    urgency={pendingTask.when}
                    phoneNumber={pendingTask.phone}
                    canComplete
                    canRelease
                  />
                ))
              )}
            </div>
            <div className='profile__feed__segment'>
              <h2>afgeronde hulpverzoeken</h2>
              {!props.completedTasks ? <Loader/> : (props.completedTasks.length === 0
                  ? (
                    <p>Je hebt geen afgeronde hulpverzoeken</p>
                  ) : props.completedTasks.map((completedTask) => (
                    <TaskCard
                      key={completedTask.id}
                      onMutate={fetchTasks}
                      id={completedTask.id}
                      title={`${completedTask.TaskType.icon} ${completedTask.TaskType.name}`}
                      location={completedTask.zipcode}
                      description={completedTask.description}
                      urgency={completedTask.when}
                      status={completedTask.status}
                      phoneNumber={completedTask.phone}
                    />
                  ))
              )}
            </div>
          </div>
          <div className='profile__settings--container'>
            <h3>je account</h3>
            <div className='profile__button--container'>
              <a className='profile__button__edit' href='mailto:support@gewoonmensen.nl?subject=Ik%20wil%20graag%20mijn%20account%20details%20aanpassen'>Gegevens aanpassen</a>
              <a className='profile__button__delete' href='mailto:support@gewoonmensen.nl?subject=Ik%20wil%20graag%20mijn%20account%20verwijderen'>Verwijder</a>
            </div>
            <div className='profile__checkbox--container'>
              <Checkbox
                id='digest'
                name='digest'
                type='checkbox'
                checked={props.user.proximityDigestSubscribe}
                onChange={e => toggleSubscribeDigest(e.target.checked)}
                label={<label>Email ontvangen met hulpvragen in mijn omgeving (max 2 per week)</label>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user,
  pendingTasks: state.me.pendingTasks,
  completedTasks: state.me.completedTasks
});

const mapDispatchToProps = {
  getPendingTasks,
  getCompletedTasks,
  getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
