import { applyMiddleware, createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { reducer as application } from './application/reducer'
import { reducer as auth } from './auth/reducer'
import { reducer as events } from './events/reducer'
import { reducer as me } from './me/reducer'
import { reducer as tasks } from './tasks/reducer'

const persistConfig = {
  version: 1,
  key: 'root',
  storage,
  whitelist: ['auth', 'application']
}

const rootReducer = combineReducers({
  application,
  auth,
	events,
  me,
  tasks
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => {
  let store;
  if (process.env.REACT_APP_ENVIRONMENT=== 'production') {
    store = createStore(persistedReducer, applyMiddleware(thunk))
  } else {
    store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
  }
  const persistor = persistStore(store);

  return { store, persistor }
}
