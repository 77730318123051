import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { api } from '../utils'
import { messages } from '../constants'
import { Header, Head } from '../components'

function ReopenTask(props) {
  const [reopened, setReopened] = useState(false);

  useEffect(() => {
    if (!reopened) {
      const reoopenTask = async () => {
        try {
          let apiUrl;
          if (props.match.params.id) { // if helper called it from within email
            apiUrl = `/task/${props.match.params.uuid}/status/update/${props.match.params.hash}/${props.match.params.id}`
          } else {
            apiUrl = `/task/${props.match.params.uuid}/status/update/${props.match.params.hash}`
          }
          await api.post(apiUrl, { newStatus: 'open' })
          setReopened(true)
        } catch (e) {
          toast.error(e.response.data.error || messages.somethingWentWrong)
        }
      };
      reoopenTask()
    }
  }, [reopened, props.match.params.uuid, props.match.params.hash]);

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title={reopened ? 'Je hebt de hulpaanvraag terug gezet op het prikbord!' : 'Bezig met heropenen...'}/>
      <div className='layout layout--bottom layout--top'>
        <div className='container'>
          <a href='/'>Keer terug naar de homepagina</a>
        </div>
      </div>
    </>
  );
}

export default ReopenTask;
