export const messages = {
    somethingWentWrong: () => '😕 Er is iets mis gegaan...',
    incorrectPostalcode: () => '🥺 De ingevulde postcode is onjuist...',
    repeatPassword: () => '👫 Het herhaalde wachtwoord is niet hetzelfde aan het ingevulde wachtwoord.',
    agreeToTerms: () => '✅ Om een vraag te kunnen plaatsen moet je akkoord gaan met de voorwaarden.',
    invalidRegisterToken: () => '😕 De opgegeven registratiecode is ongeldig.',
    signedupSuccessfully: () => '💪 Het registreren is gelukt! Je bent meteen ingelogd.',
    sentEmailSuccessfully: () => '📨 E-mail succesvol verzonden!',
    resetPasswordSuccessfully: () => '🔒 Wachtwoord succesvol gewijzigd.',
    copiedToClipboardSuccessfully: () => '✅ Je hebt de link gekopieëerd.',
    subscribeToggleSuccessfully: () => '✅ Je email settings zijn gewijzigd.',

    taskAlreadyClaimed: () => '😔 Helaas ben je net te laat... Iemand anders heeft al aangegeven te helpen!',
    taskCreated: () => '🙏 Je taak is succesvol geplaatst!',
    taskClaimed: () => '💪 Je hebt de taak succesvol geclaimed!',
    taskCompleted: () => '❤️ Je bent een topper, bedankt voor je inzet!',
    taskReleased: () => '📩 Je hebt je hulp weer ingetrokken. Voor vragen kan je contact met ons opnemen!',
    taskDeletedSuccessfully: () => '✅ Oproep succesvol verwijderd!',
    taskEditedSuccessfully: () => '✅ Oproep succesvol aangepast!',
    taskNotAvailable: (e) => `😕 Deze taak is al ${e}...`,

    invalidDonateAmount: () => `😕 Dit is geen geldige donatie...`,
    minimumDonateAmount: () => `😕 Minimum is €2`,
  }
