import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import './Footer.scss'

export function Footer() {
  const location = useLocation()
  return (
    <footer className='footer'>
      <div className='layout'>
        <div className="footer__content">
          <div className='footer__content__left'>
            <br></br>
            <br></br>
          </div>
					<div className='footer__content__middle'>
          </div>
          <div className='footer__content__right'>
          </div>
        </div>
        <p className='footer__copyright'>gewoonmensen.nl &copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  )
}
