import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
 
/**
 * Accepts metaPage with args
 * - title
 * - description
 * - keywords []
 * - robots
 * - OGTitle
 * - OGDescription
 * - OGStructuredData
 * - prerenderStatusCode
 */
export function Head(props) {
  const { metaPage } = props
  const location = useLocation()

  return (
    <Helmet>
      <link rel="canonical" href={`https://www.gewoonmensen.nl${location.pathname || '/'}`} />
      <link rel="icon" href="/favicon.ico"/>
      <link rel="apple-touch-icon" href="/logo192.png"/>
      <link rel="manifest" href="/manifest.json"/>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="fragment" content="!" />
      <meta name="author" content="Gewoon Mensen" />


      {/* Google Tag Manager */}
      <script>{
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}');`
      }</script>

      {/* prerender optimization */}
      {metaPage && metaPage.prerenderStatusCode && (<meta name="prerender-status-code" content={metaPage.prerenderStatusCode} />)}

        {/* dynamic meta tags */}
      <title lang="nl">{metaPage && metaPage.title}</title>
      <meta name="description" content={metaPage && metaPage.description} />
      <meta name="keywords" content={metaPage && (metaPage.keywords || []).join(',')} />
      <meta name="robots" content={metaPage && metaPage.robots || "index,follow"} />
      
      {/* OG tags */}
      <meta property="og:locale" content="nl_NL" />
      <meta property="og:title" content={metaPage && metaPage.OGTitle} />
      <meta property="og:description" content={metaPage && metaPage.OGDescription} />
      <meta property="og:image" itemprop="image" content={(metaPage && metaPage.OGImage) ? metaPage.OGImage : 'http://www.gewoonmensen.nl/img/ik_help.jpg'} />
      <meta property="og:image:secure_url" itemprop="image" content={(metaPage && metaPage.OGImage) ? metaPage.OGImage : 'https://www.gewoonmensen.nl/img/ik_help.jpg'} />
      <meta property="og:url" content={(metaPage && metaPage.OGUrl) ? metaPage.OGUrl : 'https://www.gewoonmensen.nl/'} />
      <meta property="fb:app_id" content="247965812991867"/>
      <meta property="og:updated_time" content="1588168132" />
      <meta property="og:image:alt" content={(metaPage && metaPage.OGImageAlt) ? metaPage.OGImageAlt : 'Blauwe knop met de tekst ik help'} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Gewoon Mensen" />

      {metaPage && metaPage.OGStructuredData && (<script type="application/ld+json">{metaPage.OGStructuredData}</script>)}
      
    </Helmet>
  );
};

export default Head
