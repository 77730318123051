import { api } from '../../utils'
import { toast } from 'react-toastify'
import { messages } from '../../constants'

export const actions = {
  login: '@@auth/login',
  signup: '@@auth/signup',
  resetPassword: '@@auth/resetPassword',
  getProfile: '@@auth/profile'
}

export function postLogin(email, password) {
  return (dispatch) => {
    api.post('/auth/login', {email, password})
      .then((res) => {
        dispatch({
          type: actions.login,
          data: res.data.data
        })
      })
      .catch((e) =>
        toast.error(messages.somethingWentWrong)
      )
  }
}

export function postSignup(firstName, lastName, email, phone, password, postalCode) {
  return dispatch => {
    api.post('/auth/signup', {firstName, lastName, email, phone, password, zipcode: postalCode})
      .then((res) => {
        dispatch({
          type: actions.login,
          data: res.data.data
        })
        toast.success(messages.signedupSuccessfully)
      })
      .catch((e) => {
        toast.error(e.response.data.error || messages.somethingWentWrong)
      })
  }
}

export function postPresignup(uuid, firstName, lastName, email, phone, password, postalCode) {
  return dispatch => {
    api.post('/auth/ongewoon/signup', {uuid, firstName, lastName, email, phone, password, zipcode: postalCode})
      .then((res) => {
        dispatch({
          type: actions.login,
          data: res.data.data
        })
      })
      .catch((e) => {
        toast.error(e.response.data.error || messages.somethingWentWrong)
      })
  }
}

export function postResetPassword(passwordToken, password) {
  return dispatch => {
    api.post('/auth/reset', {passwordToken, password})
      .then((res) => {
        dispatch({
          type: actions.resetPassword,
          data: res.data.data
        })
        toast.success(messages.resetPasswordSuccessfully)
      })
      .catch((e) =>
        toast.error(messages.somethingWentWrong)
      )
  }
}

export function logout() {
  return (dispatch) => dispatch({
    type: actions.login,
    data: null
  })
}

export function getProfile() {
  return (dispatch) => {
    api.get('/auth/profile')
      .then((res) => {
          dispatch({
          type: actions.getProfile,
          data: res.data.data
        })
      })
      .catch((e) => console.log(e))
  }
}
