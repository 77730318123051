import {api} from '../../utils'

export const actions = {
  setPostalcode: '@@application/postalCode',
  getStats: '@@application/stats/get'
}

export function setPostalCode(postalCode) {
  return (dispatch) => dispatch({
    type: actions.setPostalcode,
    data: postalCode
  })
}

export function getStats() {
  return (dispatch) => {
    api.get('/stats/tasks')
      .then((res) => {
        dispatch({
          type: actions.getStats,
          data: res.data.data
        })
      })
      .catch((e) => console.log(e))
  }
}
