import { actions } from './actions'

const initialState = {
  postalCode: null,
  stats: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.setPostalcode:
      return reduceSetPostalCode(state, action);
    case actions.getStats:
      return reduceGetStats(state, action);
    default:
      return state
  }
};

const reduceSetPostalCode = (state, action) => ({
  ...state,
  postalCode: action.data
});

const reduceGetStats = (state, action) => ({
  ...state,
  stats: action.data
});
