import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import { postLogin } from '../../store'
import { Header, Head } from '../../components'

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = (e) => {
    e.preventDefault();

    props.postLogin(email, password);
  };

  if (props.user) {
    return (
      <Redirect to='/'/>
    )
  }

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='login'/>
      <div className='layout layout--top layout--bottom'>
        <div className='container'>
          <form onSubmit={login}>
            <div className='form-segment form-segment--text'>
              <label htmlFor='email'>email</label>
              <input
                id='email'
                name='email'
                type='text'
                autoComplete='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='form-segment form-segment--text'>
              <label htmlFor='password'>wachtwoord</label>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <input type='submit' value='inloggen'/>
          </form>
          <p><Link to="/wachtwoord-vergeten">Wachtwoord vergeten?</Link></p>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = { postLogin };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
