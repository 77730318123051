import { actions } from './actions'

const initialState = {
  bevrijdingsdag: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.getBevrijdingsdag:
      return reduceGetBevrijdingsdag(state, action);
    default:
      return state
  }
}

const reduceGetBevrijdingsdag = (state, action) => ({
  ...state,
  bevrijdingsdag: action.data
})
