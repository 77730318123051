import React from 'react'

import { ReactComponent as IntroIllustration } from '../../assets/illustrations/intro.svg'

import './Intro.scss'

export function Intro() {
	return (
		<div className='intro'>
			<h1>ALLE VRIJWILLIGERS BEDANKT!!  ❤️</h1>
			<div className='intro__body'>
				<div className='intro__body__textleft'>
					<h3>Gewone initiatiefneem mensen 💪</h3>
					<ul>
						<li><a href="https://www.linkedin.com/in/naut-van-teeseling-478268185" target="_blank">Naut van Teeseling</a></li>
						<li><a href="https://www.linkedin.com/in/ambernagelhout" target="_blank">Amber Nagelhout</a></li>
						<li><a href="https://www.linkedin.com/in/thijs-de-jongh-644395161" target="_blank">Thijs de Jongh</a></li>
						<li><a href="https://www.linkedin.com/in/jasper-veen" target="_blank">Jasper Veen</a></li>
					</ul>
					<br/>
					<h3>Gewone software mensen 🤓</h3>
					<ul>
						<li><a href="https://www.linkedin.com/in/janssenl/" target="_blank">Loek Janssen</a></li>
						<li><a href="https://www.linkedin.com/in/mike-duister-358991ab/" target="_blank">Mike Duister</a></li>
						<li><a href="https://www.linkedin.com/in/ahsan-fazal/" target="_blank">Ahsan Fazal</a></li>
						<li><a href="https://www.linkedin.com/in/joep-de-jong/" target="_blank">Joep de Jong</a></li>
						<li><a href="https://www.linkedin.com/in/ceesjol/" target="_blank">Cees Jol</a></li>
						<li><a href="https://www.linkedin.com/in/rvanlaak/" target="_blank">Richard van Laak</a></li>
						<li><a href="https://www.linkedin.com/in/jornkoch" target="_blank">Jorn Koch</a></li>
						<li><a href="https://www.linkedin.com/in/lucastwisk" target="_blank">Lucas Twisk</a></li>
						<li><a href="https://www.linkedin.com/in/daanalkemade" target="_blank">Daan Alkemade</a></li>
						<li><a href="https://www.linkedin.com/in/emilebons" target="_blank">Emile Bons</a></li>
					</ul>
					<a href="https://brightcape.nl/team" target="_blank">Bright Cape team:</a>
					<ul>
						<li><a href="https://www.linkedin.com/in/annabella-hermans-b043a893" target="_blank">Annabella Hermans</a></li>
						<li><a href="https://www.linkedin.com/in/anniek-hegeman-1364a3141" target="_blank">Anniek Hegeman</a></li>
						<li><a href="https://www.linkedin.com/in/jef-van-den-buijs" target="_blank">Jef van den Buijs</a></li>
						<li><a href="https://www.linkedin.com/in/youp-suurmeijer-7386a466" target="_blank">Youp Suurmeijer</a></li>
						<li><a href="https://www.linkedin.com/in/thomas-van-rijsselt" target="_blank">Thomas van Rijsselt</a></li>
						<li><a href="https://www.linkedin.com/in/r%C3%A9gis-van-der-sommen-9b22b9a5/" target="_blank">Regis van der Sommen</a></li>
					</ul>
					<a href="https://miyagi.solutions/about" target="_blank">Miyagi Solutions team:</a>
					<ul>
						<li><a href="https://www.linkedin.com/in/dale-wesdorp-091b36111" target="_blank">Dale Wesdorp</a></li>
						<li><a href="https://www.linkedin.com/in/mozes-van-de-kar" target="_blank">Mozes van der Kar</a></li>
						<li><a href="https://www.linkedin.com/in/joppewouts" target="_blank">Joppe Wouts</a></li>
					</ul>
					<br/>
					<h3>Gewone marketing, social, en SEO mensen 📊</h3>
					<ul>
						<li><a href="https://www.linkedin.com/in/simonetteverwoest" target="_blank">Simonette Verwoest</a></li>
						<li><a href="https://www.linkedin.com/in/merel-meessen-484578142" target="_blank">Merel Meessen</a></li>
						<li><a href="https://www.linkedin.com/in/tessa-dekeukeleire-272746a7" target="_blank">Tessa Dekeukeleire</a></li>
						<li><a href="https://www.linkedin.com/in/marshagoei" target="_blank">Marscha Goei</a></li>
						<li><a href="https://www.linkedin.com/in/abe-minnema-866439135" target="_blank">Abe Minnema</a></li>
						<li><a href="https://www.linkedin.com/in/nick-luijt-9098b1121" target="_blank">Nick Luijt</a></li>
						<li><a href="https://www.linkedin.com/in/joostreijnierse" target="_blank">Joost Reijnierse</a></li>
						<li><a href="https://www.linkedin.com/in/slinders" target="_blank">Stan Linders</a></li>
						<li><a href="https://www.linkedin.com/in/sandervanderneut" target="_blank">Sander van der Neut</a></li>
						<li><a href="https://www.linkedin.com/in/annemiddeldorp" target="_blank">Anne Middeldorp</a></li>
						<li><a href="https://www.linkedin.com/in/rafael-leeuwis-302242143" target="_blank">Rafael Leeuwis</a></li>
						<li>Nadine Krijnsen</li>
						<li><a href="https://www.linkedin.com/in/barttemme" target="_blank">Bart Temme</a></li>
					</ul>
					<span><a href="https://newcraftgroup.com/" target="_blank">Newcraft</a> + <a href="https://oakisnow.com/" target="_blank">Oak</a> team:</span>
					<ul>
						<li><a href="https://www.linkedin.com/in/maaike-woudstra-68259068" target="_blank">Maaike Woudstra</a></li>
						<li><a href="https://www.linkedin.com/in/tamara-zwinkels" target="_blank">Tamara Zwinkels</a></li>
						<li><a href="https://www.linkedin.com/in/annelies-lubbe" target="_blank">Annelies Lubbe</a></li>
						<li><a href="https://www.linkedin.com/in/jannaoosthoek" target="_blank">Janna Oosthoek</a></li>
					</ul>
				</div>
				<div className='intro__body__textright'>
					<h3>Gewone product mensen 📱</h3>
					<ul>
						<li><a href="https://www.linkedin.com/in/dante-van-der-heijden-027b8259/" target="_blank">Dante van der Heijden</a></li>
						<li><a href="https://www.linkedin.com/in/willem-evers-85a622a6" target="_blank">Willem Evers</a></li>
						<li><a href="https://www.linkedin.com/in/thomtrentelman" target="_blank">Thom Trentelman</a></li>
					</ul>
					<a href="https://codedazur.com/" target="_blank">Code d’Azur team:</a>
					<ul>
						<li><a href="https://www.linkedin.com/in/niknieuwenhuijs" target="_blank">Nik Nieuwenhuijs</a></li>
						<li><a href="https://www.linkedin.com/in/anna-van-de-vijver" target="_blank">Anna van de Vijver</a></li>
					</ul>
					<br/>
					<h3>Gewone operations mensen 🏗️</h3>
					<ul>
						<li>Stijn Hartman</li>
						<li><a href="https://www.linkedin.com/in/anniek-wiltink-319a96116" target="_blank">Anniek Wiltink</a></li>
						<li><a href="https://www.linkedin.com/in/thomas-spruijt-b11869140" target="_blank">Thomas Spruijt</a></li>
						<li><a href="https://www.linkedin.com/in/salimhadri" target="_blank">Salim Hadri</a></li>
						<li>David Oudega</li>
						<li>Rolf Steenmetser</li>
						<li>Tijn Bartelings</li>
						<li>Hugo Soons</li>
						
						

					</ul>
					Onze regiomanagers:
					<ul>
						<li>Pien Killiaan</li>
						<li>Otto van Wassenaar</li>
						<li>Zelda Geels</li>
						<li>Floris van Buren</li>
						<li>Ries van Dijk</li>
						<li>Manouk de Groot</li>
						<li>Julia Lindeboom</li>
						<li>Zoe van Helvoirt</li>
						<li>Stijn van Waal</li>
						<li>Solange van Doorn</li>
						<li>Tim Akkersdijk</li>
						<li>Pepijn Franken</li>
						<li>Guido van Winden</li>
						<li>Olivia de Korte</li>
					</ul>
					Studentenverenigingen:
					<ul>
						<li>RSC</li>
						<li>USC</li>
						<li>UVSV</li>
						<li>DSC</li>
						<li>Minerva</li>
						<li>Ceres</li>
						<li>ASC</li>
						<li>N.S.V. Carolus Magnus</li>
						<li>Arboricultura Arnhem</li>
						<li>ESC</li>
						<li>Vidar</li>
						<li>Laurentius</li>
						<li>ASV Taste</li>
						<li>Albertus</li>
						<li>Vindicat</li>
						<li>Virgiel</li>
						<li>St Jansbrug</li>
					</ul>
				</div>
				<div className='intro__body__textunder'>
					<span>
						...en alle andere gewone mensen die door heel Nederland hebben geholpen om flyers rond te brengen, posters op te hangen, tompoucen uit te delen en elke andere vorm van hulp geboden heeft aan een ander in deze lastige tijd.
					</span>
					<br/>
					<span>
						Wie we ook niet willen vergeten te bedanken zijn de bedrijven die ons een steuntje in de rug hebben gegeven door hun diensten gratis of tegen een gereduceerd tarief aan te bieden om zo meer mensen te kunnen helpen:
					</span>
					<p>
						UniPartners, Ciphix, Google, GitHub, Rabobank, Jira/Atlassian, AWS, Slack, Unu, Sixt, Bakker voor de tompoucen, QuickVision Creative, RemEon VOIP Telecom, en Picnic. <b>Dank jullie wel! 🎉</b>
					</p>
				</div>
			</div>
		</div>
	)
}
