import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as ClockIcon } from '../assets/icons/clock.svg'
import { ReactComponent as ApprovedIcon } from '../assets/icons/approved.svg'
import differenceInMinutes from "../utils/differenceInMinutes";
import { getCompletedTasks } from '../store'

import './DoneTaskCard.scss'

function Component(props) {
    const formatTimestamp = (rawTimestamp) => {
        if (!rawTimestamp) { return 'Onlangs'}

        const minutesAgo = differenceInMinutes(new Date(rawTimestamp), new Date());
        if (minutesAgo < 5) { return '5 minuten geleden' };
        if (minutesAgo < 60) { return `${minutesAgo} minuten geleden` };
        if (minutesAgo < 1440) { return `${Math.floor(minutesAgo / 60)} uur geleden` };
        if (minutesAgo < 2880) { return '1 dag geleden' };
        if (minutesAgo < 10080) { return `${Math.floor(minutesAgo / 1440)} dagen geleden` };
        if (minutesAgo < 20160) { return '1 week geleden' };
        return `${Math.floor(minutesAgo / 10080)} weken geleden`;
    }

    return (
        <div className='claimed-task-card'>
            <div className='claimed-task-card__content'>
                <div className='claimed-task-card__content__header'>
                     <span className='claimed-task-card__content__header__verified'>
                      <span className="claimed-task-card__content__header__verified__tooltip">Gelukt!</span>
                        <ApprovedIcon />
                    </span>
                    <span className='claimed-task-card__content__header__type'>{props.title}</span>
                    <span className='claimed-task-card__content__header__location'>{props.location}</span>
                </div>
                <div className='claimed-task-card__content__footer'>
                    {<span><ClockIcon/>{formatTimestamp(props.doneAt || props.confirmedAt)}</span>}
                    <span>Geholpen door: {props.doneBy}</span>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = {
    getCompletedTasks
};

export const DoneTaskCard = connect(mapStateToProps, mapDispatchToProps)(Component);
