import { actions } from './actions'

const initialState = {
  pendingTasks: null,
  completedTasks: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.getPendingTasks:
      return reduceGetPendingTasks(state, action);
    case actions.getCompletedTasks:
      return reduceCompletedTask(state, action);
    default:
      return state
  }
}

const reduceGetPendingTasks = (state, action) => ({
  ...state,
  pendingTasks: action.data
})

const reduceCompletedTask = (state, action) => ({
  ...state,
  completedTasks: action.data
})
