import { api } from '../../utils'
import { toast } from 'react-toastify'
import { messages } from '../../constants'

export const actions = {
  getTypes: '@@tasks/types',
  getTasks: '@@tasks/list',
  getDoneTasks: '@@tasks/listDone',
  createTask: '@@tasks/create'
}

export function getTaskTypes() {
  return (dispatch) => {
    api.get('/task/types')
      .then((res) => {
        dispatch({
          type: actions.getTypes,
          data: res.data.data
        })
      })
      .catch((e) => console.log(e))
  }
}

export function getTasks(zipcode, taskTypeId, when) {
  return (dispatch) => {
    const data = {
      zipcode,
      ...taskTypeId && {taskTypeId},
      ...when && {when}
    };
    api.get('/tasks', {params: data})
      .then((res) => {
        dispatch({
          type: actions.getTasks,
          data: res.data.data
        })
      })
      .catch((e) =>
        toast.error(messages.somethingWentWrong)
      )
  }
}

export function getDoneTasks() {
    return (dispatch) => {
        api.get('/task/done-tasks')
            .then((res) => {
                dispatch({
                    type: actions.getDoneTasks,
                    data: res.data.data
                })
            })
            .catch((e) => console.log(e))
    }
}

export function createTask(loggedIn, firstName, lastName, phone, email, zipcode, taskTypeId, when, description) {
  return (dispatch) => {
    let url = loggedIn?'/me/task':'/task'
    api.post(url, {firstName, lastName, phone, email, zipcode, taskTypeId, when, description})
      .then((res) => {
        dispatch({
          type: actions.createTask,
          data: res.data.data
        });
        toast.success(messages.taskCreated)
      })
      .catch((e) =>
        toast.error((e.response && e.response.data.error) || messages.somethingWentWrong)
      )
  }
}
