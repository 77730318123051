import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import './Banner.scss'

function Banner(props) {
  const [bannerClicked, setBannerClicked] = useState(false);
  return (
    <div className='banner'>
      {bannerClicked ? (<Redirect to='/koningsdag'/>) : 
        (
        <div className='banner--content' onClick={() => setBannerClicked(true)}>
          Wij zoeken jou! Klik hier om een tompouce te doneren voor onze koningsdag actie!!
        </div>
        )
      }
    </div>
  )
}

export default Banner;