const metaPages = {};
metaPages.Dashboard = {
    title: 'Gewoon mensen die mensen willen helpen',
    description: 'Nederland is geraakt door de coronacrisis. Bied je helpende hand aan op dit online pribord.',
    keywords: ['helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Gewoon mensen die mensen willen helpen',
    OGDescription: 'Nederland is geraakt door de coronacrisis. Bied je helpende hand aan op dit online pribord.',
	OGStructuredData: `
	[
		{
			"@context": "http://schema.org",
			"@type": "Organization",
			"name": "Gewoon mensen",
			"url" : "http://www.your-company-site.com",
			"email": "support@gewoonmensen.nl",
			"telephone": "+31 (0)85 1309 656",
			"address": {
				"addressLocality": "Haarlem, The Netherlands",
				"postalCode": "2012DA",
				"streetAddress": "Frederikspark 6"
			},
			"logo": {
				"@context": "http://schema.org",
				"@type": "ImageObject",
				"url": "https://www.gewoonmensen.nl/img/logo_square.png",
				"width": "250"
			},
			"sameAs": [
				"https://www.instagram.com/gewoonmensen.nl",
				"https://www.facebook.com/gewoonmensen.nl",
				"https://twitter.com/GewoonMensen"
			]
		},
		{
			"@context": "http://schema.org",
			"@type": "WebSite",
			"name": "Gewoon mensen",
			"alternateName": "Gewoon mensen die mensen willen helpen",
			"url": "https://www.gewoonmensen.nl"
		}
	]
	`,
}


metaPages.TaskOverview = {
    title: 'Overzicht van hulpaanvragen',
    description: 'Op dit prikbord staan alle openstaande hulpvragen. Klik op "Ik Help" om een helpende hand aan te bieden.',
    keywords: ['helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp', 'online help prikbord'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Overzicht van hulpaanvragen',
    OGDescription: 'Op dit prikbord staan alle openstaande hulpvragen. Klik op "Ik Help" om een helpende hand aan te bieden.',
	OGStructuredData: '',
}

metaPages.Profile = {
    title: 'Jou profiel en activiteit',
    description: 'Vind hier het overzicht van al je aangeboden hulp en een overzicht van je profiel instellingen.',
    keywords: '',
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Jou profiel en activiteit',
    OGDescription: 'Vind hier het overzicht van al je aangeboden hulp en een overzicht van je profiel instellingen.',
	OGStructuredData: '',
}


metaPages.Register = {
    title: 'Maak een account aan',
    description: 'Maak een account aan om anderen nu te kunnen helpen.',
    keywords: '',
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Maak een account aan',
    OGDescription: 'Maak een account aan om anderen nu te kunnen helpen.',
	OGStructuredData: '',
}


metaPages.NotFound = {
    title: 'Pagina niet gevonden',
    description: 'Deze pagina bestaat niet. Navigeer naar de homepagina voor een overzicht.',
    keywords: '',
	robots: '',
	prerenderStatusCode: '404',
    OGTitle: 'Pagina niet gevonden',
    OGDescription: 'Deze pagina bestaat niet. Navigeer naar de homepagina voor een overzicht.',
	OGStructuredData: '',
}

metaPages.AboutUs = {
    title: 'Over ons',
    description: 'Gewoon mensen is een vrijwilligers organizatie om hulpbehoevenden te koppelen aan hulpaanbieders.',
    keywords: ['helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Over ons',
    OGDescription: 'Gewoon mensen is een vrijwilligers organizatie om hulpbehoevenden te koppelen aan hulpaanbieders.',
	OGStructuredData: '',
}

metaPages.Faq = {
    title: 'Veelgestelde vragen',
    description: 'Een overzicht van de meest gestelde vragen over het online prikbord van Gewoon Mensen, het helpen, en geholpen worden.',
    keywords: ['helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Veelgestelde vragen',
    OGDescription: 'Een overzicht van de meest gestelde vragen over het online prikbord van Gewoon Mensen, het helpen, en geholpen worden.',
	OGStructuredData: `
	{
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "Wie zijn gewoonmensen.nl?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Gewoonmensen.nl is een intiatief van 4 studenten: Naut, Jasper, Thijs en Amber  Toen de universiteiten vanwege Corona dicht gingen bedachten zij dit initiatief om hun steentje aan de maatschappij bij te dragen. Ondertussen bestaat gewoonmensen.nl uit meer dan 30 personen die er dagelijks voor zorgen dat gewone mensen andere mensen kunnen helpen."
				}
			},
			{
				"@type": "Question",
				"name": "Wat doet gewoonmensen.nl?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "We verbinden mensen die hulp aanbieden aan mensen die hulp nodig hebben. Niets meer en niets minder."
				}
			},
			{
				"@type": "Question",
				"name": "Waarom bestaat de website gewoonmensen.nl?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "We geloven dat elk gewoon mens klaar staat voor een ander in tijden van nood. Dat zien we nu ook om ons heen. We hopen dat de wereld na Covid-19 een stukje mooier geworden is en dat we elkaar blijven helpen."
				}
			},
			{
				"@type": "Question",
				"name": "Wat kun je op gewoonmensen.nl vragen?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Individuen kunnen hulp vragen voor bijv: <ul><li>boodschappen doen</li><li>hond uitlaten</li><li>medicijnen ophalen</li><li>een gezellig gesprek aan de telefoon</li><li>bijles geven</li></ul> De vraag moet wel gerelateerd zijn aan Covid-19 en mag geen commercieel doel hebben. Als u een vraagt stelt is het fijn als u zoveel mogelijk informatie deelt, dat maakt de kans groter dat iemand u wil helpen."
				}
			},
			{
				"@type": "Question",
				"name": "Is gewoonmensen.nl gratis?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Ja! Wij hebben gemerkt dat gewone mensen elkaar graag willen helpen. Zeker in de huidige situatie. En daar hoeft geen vergoeding tegenover hoeft te staan. Als u een vrijwilliger wilt belonen staat u dat natuurlijk vrij, maar dat is dan tussen u en de vrijwilliger."
				}
			},
			{
				"@type": "Question",
				"name": "Wie kunnen er hulp vragen op gewoonmensen.nl?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Iedereen. Op gewoonmensen.nl kan iedereen om hulp vragen: zowel individuen als instanties."
				}
			},
			{
				"@type": "Question",
				"name": "Wie zijn de vrijwilligers op gewoonmensen.nl?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "De bijna 40.000 vrijwilligers zijn gewoon mensen die andere mensen willen helpen. Het is begonnen als een initiatief onder studenten maar ondertussen bestaat de groep vrijwilligers uit gewone mensen van elke leeftijd en bevolkingsgroep."
				}
			},
			{
				"@type": "Question",
				"name": "Is het veilig om gewoonmensen.nl in te schakelen?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "De vrijwilligers zijn niet door gewoonmensen.nl gescreend. Voordat iemand u helpt neemt diegene altijd eerst contact met u op. Vraag gerust om een legitimatie en maak daar als u er niet gerust op bent een foto van. Wij raden u af om uw pinpas en uw huissleutels af te geven."
				}
			},
			{
				"@type": "Question",
				"name": "Hoe werkt het?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Op de website kunt u aangeven of u hulp zoekt of hulp nodig heeft. <a href='https://player.vimeo.com/video/401003090'>Klik hier</a> voor een korte video met uitleg, of volg de instructies hieronder voor een stap-voor-stap omschrijving.<br/><br/><strong>Ik heb hulp nodig:</strong><ol><li>ga naar de website: <a href='https://gewoonmensen.nl'>gewoonmensen.nl</a></li><li>klik op de knop <a href='https://www.gewoonmensen.nl/vraag-hulp'>hulp nodig</a>.</li><li>beschrijf je hulpvraag in het tekstvak, doe dit zo gedetailleerd mogelijk. Zo heb je meer kans dan een vrijwilliger je kan helpen.</li><li>vul je contactgegevens in.</li><li>upload je hulpvraag op het online prikbord.</li></ol>Na het doorlopen van de stappen kunnen alle vrijwilligers in jouw omgeving de hulpvraag bekijken. We streven ernaar om je hulpvraag binnen 24 uur aan een hulpbieder te koppelen.<br/><br/><strong>Ik wil graag helpen:</strong><ol><li>ga naar de website: <a href='https://gewoonmensen.nl'>gewoonmensen.nl</a></li><li>creëer een account of log in via het menu rechtsboven in het scherm.</li><li>klik op de knop <a href='https://gewoonmensen.nl/ik-wil-helpen'>helpen</a>.</li><li>vul je postcode in en bekijk alle hulpvragen in je omgeving.</li><li>klik op de knop 'ik help', om de hulpvraag van het prikbord te halen en te helpen.</li><li>neem contact op met de hulpbehoevenden en maak een afspraak.</li><li>na het helpen klik je op de knop 'afgerond'. Kan je toch niet helpen of heb je niet kunnen helpen, klik dan op 'terugzetten'.</li></ol>"
				}
			},
			{
				"@type": "Question",
				"name": "Hoe lang duurt het voordat ik een reactie krijg?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Dat is moeilijk te zeggen. Als het via de website wat langer duurt gaat ons team achter de schermen aan de slag om een geschikte vrijwilliger te vinden."
				}
			},
			{
				"@type": "Question",
				"name": "Ik zie geen aanvragen bij mij in de buurt, hoe kan dat?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "De hulpvragen worden gesorteerd op afstand. Als er geen hulpvragen bij jou in de buurt zijn betekent dit dat iedereen op ons platform bij jou in de buurt geholpen is. Maar dat betekent niet dat er niemand in jouw omgeving hulp nodig heeft. Het kan zijn dat ons bereik nog niet goed genoeg is in jouw buurt. Omdat mensen die hulp nodig hebben vaak ook wat lastiger te bereiken zijn, kun jij ons helpen ook deze mensen te bereiken. Hier kan je onze <a href='https://drive.google.com/drive/folders/1wB6tgJ95x-eD2dSbeejo0g98A1C61J8O'>burenbrief</a> downloaden en printen. Je kunt deze ophangen in je lokale supermarkt, apotheek of andere openbare plekken. Zo kunnen we nog meer mensen bereiken."
				}
			},
			{
				"@type": "Question",
				"name": "Er wordt naar mijn telefoonnummer gevraagd, voor wie is dit zichtbaar?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Telefoonnummers van hulpvragers zijn alleen zichtbaar voor hulpbieders nadat er aangegeven is te willen helpen. Telefoonnummers worden gebruikt om contact te kunnen leggen ter communicatie. Telefoonnummers van vrijwilligers zijn nooit zichtbaar op de website."
				}
			},
			{
				"@type": "Question",
				"name": "Als er boodschappen worden gedaan, hoe gaat dit dan met de betaling?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Gewoonmensen.nl is een online prikbord om mensen die hulp zoeken en hulp willen aanbieden met elkaar te verbinden. De praktische zaken van de hulpvraag kunt u samen bespreken, voor de betaling raden wij het gebruik van een betaalverzoek aan of internetbankieren."
				}
			},
			{
				"@type": "Question",
				"name": "Het plaatsen van een aanvraag lukt niet, wat nu?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Een aanvraag kan alleen geplaatst worden als alle velden zijn ingevuld en de postcode correct is. Als een van deze velden niet correct is, krijgt u een foutmelding. Wil het nog steeds niet lukken, probeer dan de pagina te verversen of open de site in een andere browser."
				}
			}
		]
	}
	`,
}

metaPages.Organizations = {
    title: 'Organisaties',
    description: 'Informatie voor organisaties die hulp nodig hebben van Gewoon Mensen.',
    keywords: ['helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Organisaties',
    OGDescription: 'Informatie voor organisaties die hulp nodig hebben van Gewoon Mensen.',
	OGStructuredData: '',
}

metaPages.PostTask = {
    title: 'Plaats een hulpvraag',
    description: 'Om een hulpvraag op het online prikbord te zetten plaats je hier een verzoek.',
    keywords: ['helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Plaats een hulpvraag',
    OGDescription: 'Om een hulpvraag op het online prikbord te zetten plaats je hier een verzoek.',
	OGStructuredData: '',
}

metaPages.Terms = {
    title: 'Algemene voorwaarden',
    description: 'De algemene voorwaarden van het gebruik van de website, het helpen, en hulp aanvragen.',
    keywords: ['helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Algemene voorwaarden',
    OGDescription: 'De algemene voorwaarden van het gebruik van de website, het helpen, en hulp aanvragen.',
	OGStructuredData: '',
}

// do not index
metaPages.EditTask = {
    title: 'Pas je hulpaanvraag aan',
    description: 'Hulpaanvraag omschrijving aanpassen? Doe het hier.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Pas je hulpaanvraag aan',
    OGDescription: 'Hulpaanvraag omschrijving aanpassen? Doe het hier.',
	OGStructuredData: '',
}

// do not index
metaPages.SingleTask = {
    title: 'Help iemand uit jou buurt!',
    description: 'Bekijk hoe je kan helpen op deze pagina.',
    keywords: '',
    robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Help iemand uit jou buurt!',
    OGDescription: 'Bekijk hoe je kan helpen op deze pagina.',
	OGStructuredData: '',
}

// do not index
metaPages.ConfirmContactedTask = {
    title: 'Bevestig contact met helper',
    description: 'Geef aan dat de helper al contact met je heeft opgenomen om de hulp te verwezelijken.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Bevestig contact met helper',
    OGDescription: 'Geef aan dat de helper al contact met je heeft opgenomen om de hulp te verwezelijken.',
	OGStructuredData: '',
}

// do not index
metaPages.ReopenTask = {
    title: 'Heropen deze hulpaanvraag',
    description: 'Was deze hulpaanvraag afgerond of kon er niet meer geholpen worden? Zet het hier terug op het prikbord.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Heropen deze hulpaanvraag',
    OGDescription: 'Was deze hulpaanvraag afgerond of kon er niet meer geholpen worden? Zet het hier terug op het prikbord.',
	OGStructuredData: '',
}

// do not index
metaPages.Gedoneerd = {
    title: 'Bevrijdingsdag tompoucen actie',
    description: 'Doneer tompoucen aan ouderen om ze een hart onder de riem te steken voor bevrijdingsdag.',
    keywords: ['bevrijdingsdag', 'woningsdag', 'helpen tijdens corona', 'vrijwilligerswerk', 'corona hulp'],
	robots: '',
	prerenderStatusCode: '',
    OGTitle: 'Bevrijdingsdag tompoucen actie',
    OGDescription: 'Doneer tompoucen aan ouderen om ze een hart onder de riem te steken voor bevrijdingsdag.',
	OGStructuredData: '',
	OGImage: 'https://www.gewoonmensen.nl/img/tompouce-man.jpg',
	OGImageAlt: 'Man die een tompouce eet',
	OGUrl: 'https://www.gewoonmensen.nl/bevrijdingsdag',
}

// do not index
metaPages.Unsubscribe = {
    title: 'Afmelden voor de notificatie emails',
    description: 'Meld je af voor emails die je op de hoogte houden van hulpaanvragen bij jou in de buurt.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Afmelden voor de notificatie emails',
    OGDescription: 'Meld je af voor emails die je op de hoogte houden van hulpaanvragen bij jou in de buurt.',
	OGStructuredData: '',
}

// do not index
metaPages.ConfirmTask = {
    title: 'Bevestig hulpaanvraag afgerond',
    description: 'Bevestig dat de hulpaanvraag die openstaat succesvol is afgerond.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Bevestig hulpaanvraag afgerond',
    OGDescription: 'Bevestig dat de hulpaanvraag die openstaat succesvol is afgerond.',
	OGStructuredData: '',
}

// do not index
metaPages.DeleteTask = {
    title: 'Sluit deze hulpaanvraag',
    description: 'Is deze hulpaanvraag niet meer relevant? Sluit deze hulpaanvraag op deze pagina.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Sluit deze hulpaanvraag',
    OGDescription: 'Is deze hulpaanvraag niet meer relevant? Sluit deze hulpaanvraag op deze pagina.',
	OGStructuredData: '',
}

// do not index
metaPages.ConfirmClaimTask = {
    title: 'Sluit deze hulpaanvraag',
    description: 'Is deze hulpaanvraag niet meer relevant? Sluit deze hulpaanvraag op deze pagina.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Sluit deze hulpaanvraag',
    OGDescription: 'Is deze hulpaanvraag niet meer relevant? Sluit deze hulpaanvraag op deze pagina.',
	OGStructuredData: '',
}

// do not index
metaPages.Login = {
    title: 'Log in',
    description: 'Log in om je profiel te bekijken en te reageren op hulpaanvragen.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Log in',
    OGDescription: 'Log in om je profiel te bekijken en te reageren op hulpaanvragen.',
	OGStructuredData: '',
}

// do not index duplicate of register
metaPages.Preregister = {
    title: 'Maak een account aan',
    description: 'Maak een account aan om anderen nu te kunnen helpen.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Maak een account aan',
    OGDescription: 'Maak een account aan om anderen nu te kunnen helpen.',
	OGStructuredData: '',
}

// do not index
metaPages.ForgotPassword = {
    title: 'Stel je nieuwe paswoord in',
    description: 'Als je je paswoord bent vergeten kan je hier een nieuwe instellen.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Stel je nieuwe paswoord in',
    OGDescription: 'Als je je paswoord bent vergeten kan je hier een nieuwe instellen.',
	OGStructuredData: '',
}

// do not index
metaPages.ResetPassword = {
    title: 'Stel je nieuwe paswoord in',
    description: 'Als je je paswoord bent vergeten kan je hier een nieuwe instellen.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Stel je nieuwe paswoord in',
    OGDescription: 'Als je je paswoord bent vergeten kan je hier een nieuwe instellen.',
	OGStructuredData: '',
}

// do not index duplicate of register
metaPages.Preregister = {
    title: 'Maak een account aan',
    description: 'Maak een account aan om anderen nu te kunnen helpen.',
    keywords: '',
	robots: 'noindex,nofollow',
	prerenderStatusCode: '',
    OGTitle: 'Maak een account aan',
    OGDescription: 'Maak een account aan om anderen nu te kunnen helpen.',
	OGStructuredData: '',
}


export default metaPages;
