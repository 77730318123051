import React from 'react'

import { Head } from '../components'
import { ReactComponent as WhatsAppIcon } from '../assets/brands/WhatsApp.svg';
import { ReactComponent as FacebookIcon } from '../assets/brands/Facebook.svg'
import { Header } from '../components'
import { BASE_URL_FRONT_END } from '../helpers/const';
import metaPages from './metapages'

import './Gedoneerd.scss'

function Gedoneerd(props) {
	const shareFacebookMessage = `Doe je mee? Op Bevrijdingsdag verrassen we samen 100.000 eenzame ouderen met een tompouce. Klein gebaar, groot plezier. €1 = een tompouce. ${BASE_URL_FRONT_END}/bevrijdingsdag`;
	const shareTwitterMessage = `Ik heb tompoucen gedoneerd voor de Bevrijdingsdag-actie van GewoonMensen! 1 tompouce = een blije oudere. Doe je ook mee?`;
	const shareWhatsAppMessage = shareFacebookMessage;

	const urlToShare = `${BASE_URL_FRONT_END}/bevrijdingsdag`;
	const hashtags = ['gewoonmensen', 'bevrijdingsdag'];
	return (
		<>
			<Head metaPage={props.metaPage} />
			<Header title='Je hebt gedoneerd'/>
			<div className='layout layout--top layout--bottom gedoneerd'>
				<h2>Dank je wel!</h2>
				<p>
					Fijn dat je meehelpt om 100.000 ouderen te verrassen.<br/>Maak nog meer mensen blij en deel dit met je vrienden via Facebook, Twitter, of WhatsApp:
				</p>
				<div className="gedoneerd__inline-icons">
					<a
						target='_blank'
						rel='noopener noreferrer'
						onClick={(e) => { e.preventDefault(); window.FB.ui({
							display: 'popup',
							method: 'share',
							caption: metaPages.Gedoneerd.OGDescription,
							description: shareFacebookMessage,
							picture: metaPages.Gedoneerd.OGImage,
							quote: shareFacebookMessage,
							title: metaPages.Gedoneerd.OGTitle,
							href: `${BASE_URL_FRONT_END}/bevrijdingsdag`,
						}, () => {})}}
						className='gedoneerd__inline-icons--icon'
					>
						<FacebookIcon/>
					</a>
					<a
						class='gedoneerd__inline-icons--icon twitter-share-button'
						href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareTwitterMessage)}&url=${encodeURIComponent(urlToShare)}&hashtags=${encodeURIComponent(hashtags.join(','))}`}
						data-size='large'
					>
						Tweeten
					</a>
					<a
						target='_blank'
						rel='noopener noreferrer'
						href={`https://wa.me/?text=${encodeURIComponent(shareWhatsAppMessage)}`}
						className='gedoneerd__inline-icons--icon'
					>
						<WhatsAppIcon/>
					</a>
				</div>
			</div>
		</>
	);
}

export default Gedoneerd;
