import React from 'react'

import { ReactComponent as CheckmarkIcon } from '../assets/icons/checkmark.svg'

import './Checkbox.scss'

export function Checkbox(props) {
  return (
    <div className='checkbox'>
      <label className='checkbox__content'>
        <input
          type='checkbox'
          name={props.name}
          id={props.name}
          checked={props.checked}
          value={props.value}
          onChange={props.onChange}
        />
        <span>
          <CheckmarkIcon/>
        </span>
      </label>
      <label className='checkbox__label' htmlFor={props.name}>{props.label}</label>
    </div>
  )
}
