import React from 'react'

import './Header.scss'

export function Header(props) {
  return (
    <div className='header'>
      <div className='layout'>
        <h1>{props.title}</h1>
      </div>
    </div>
  )
}
