import React from 'react'

import { Header, Head } from '../components'

function NotFound(props) {
  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='404'/>
      <div className='layout layout--bottom layout--top'>
        <p>pagina niet gevonden</p>
      </div>
    </>
  );
}

export default NotFound;
