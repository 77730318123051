import React from 'react'

import { Header, Head } from '../components'

function Organizations(props) {
  return (
    <>
    <Head metaPage={props.metaPage} />
      <Header title='organisaties'/>
      <div className='layout layout--bottom layout--top'>
        <div className='organizations'>
          <h2>Hulp aanvragen</h2>
          <p>Als organisatie kan je in deze tijden handen te kort komen, via ons kan je extra hulp aanvragen. Vul
            onderstaande googleform in om direct in contact te komen met onze regiomanagers, zij zullen jullie koppelen
            aan een van de vele gewone mensen, die zich via ons hebben aangemeld om gewoon te helpen. Want uiteindelijk
            zijn we ook maar gewoon mensen die mensen willen helpen!</p>
          <p><a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe2kMdC_gyJFA9Jkmbit_iEQcYBrSZMwVA6HLZcURj95xOSKw/viewform?fbzx=5452407611054117258"
            target="_blank"
            rel="noopener noreferrer"
          ><u>Het aanvraag formulier</u></a>.</p>
          <h2>Hulp aanbieden</h2>
          <p>Als organisaties kunnen we elkaar goed helpen in tijden als deze. Heb je suggesties of ideeën? Dan kan je
            contact opnemen met ons via: mensen@gewoonmensendiemensenwillenhelpen.nl met als onderwerp "<i>Samenwerking
              [Bedrijfsnaam]</i>". Want uiteindelijk zijn we ook maar gewoon mensen die mensen willen helpen!</p>
        </div>
      </div>
    </>
  );
}

export default Organizations;
