import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { api } from '../utils'
import { messages } from '../constants'
import { Header, Head } from '../components'

function DeleteTask(props) {
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (!deleted) {
      const deleteTask = async () => {
        try {
          await api.post(`/task/${props.match.params.uuid}/status/update/${props.match.params.hash}`, { newStatus: 'archived' })
          setDeleted(true)
        } catch (e) {
          toast.error(e.response.data.error || messages.somethingWentWrong)
        }
      };
      deleteTask()
    }
  }, [deleted, props.match.params.uuid, props.match.params.hash]);

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title={deleted ? 'Je verzoek om hulp is verwijderd' : 'Bezig met verwijderen'}/>
      <div className='layout layout--bottom layout--top'>
        <div className='container'>
          <a href='/'>Keer terug naar de homepagina</a>
        </div>
      </div>
    </>
  );
}

export default DeleteTask
