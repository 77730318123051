import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'

import Banner from './Banner'
import { logout } from '../../store'
import { ReactComponent as Logo } from '../../assets/logo.svg'

import './Nav.scss'

function Component(props) {
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false)
  const location = useLocation()
  
  const showBanner = props.banner.show && props.banner.routes.includes(location.pathname);
  const logout = (e) => {
    props.logout()
  }

  return (
    <div>
      {showBanner && (<Banner/>)}
      <nav className={classnames('nav', {
        'nav--transparent': props.transparent,
        'nav--banner': showBanner,
      })}>
        <div className='layout'>
          <div className='nav__content'>
            <a href='/' className='nav__content__logo'>
              <Logo/>
            </a>
          </div>
        </div>
      </nav>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = {logout};

export const Nav = connect(mapStateToProps, mapDispatchToProps)(Component);
