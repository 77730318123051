import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { useLocation } from 'react-router-dom';
import qs from 'qs'

import { DoneTaskCard, TaskCard, Loader, Head } from '../components'
import { isValidPostalCode, cleanPostalCode } from '../utils'
import { setPostalCode as setPostalCodeRedux, getTasks } from '../store'
import { selectStyles } from '../constants'
import history from '../components/History'

import { ReactComponent as TaskOverviewIllustration } from '../assets/illustrations/task-overview.svg'
import { ReactComponent as CheckmarkIcon } from '../assets/icons/checkmark.svg'
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg'

import './TaskOverview.scss'

function TaskOverview(props) {
  const taskTypeOptions = [
    {value: null, label: (<strong>alle types</strong>)},
    ...props.taskTypes.map((taskType) => ({
      value: taskType.id,
      label: `${taskType.icon} ${taskType.name}`
    }))
  ]

  const whenOptions = [
    {value: null, label: (<strong>n.v.t.</strong>)},
    {value: 'zsm', label: 'z.s.m.'},
    {value: 'vandaag', label: 'vandaag'},
    {value: 'deze week', label: 'deze week'}
  ]

  const [loading, setLoading] = useState(true)
  const [postalCode, setPostalCode] = useState(props.postalCode || '')
  const [selectedTaskType, setSelectedTaskType] = useState(taskTypeOptions[0])
  const [selectedWhen, setSelectedWhen] = useState(whenOptions[0])
	const location = useLocation()

  const fetchTasks = () => {
    if (!postalCode) { return props.getTasks(null, selectedTaskType.value, selectedWhen.value); }
    if (!isValidPostalCode(postalCode)) return;
    props.getTasks(postalCode, selectedTaskType.value, selectedWhen.value);
  };

  useEffect(() => {
		if (location.search) {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
			const zipCode = (queryParams.zipCode || queryParams.zipcode)?.replace(' ', '').toUpperCase()
			if (zipCode) {
				setPostalCode(zipCode)
				props.setPostalCodeRedux(zipCode)
				history.push()
			}
		}
	}, [])

  useEffect(() => {
    fetchTasks();
    setLoading(true);
  }, [postalCode, selectedTaskType, selectedWhen]);

  useEffect(() => {
    setLoading(false);
  }, [props.availableTasks, props.doneTasks]);

  return (
    <>
      <Head metaPage={props.metaPage} />
      <div className='task-overview__spacer'/>
      <div className="layout layout--bottom">
        <div className='task-overview'>
          <div className='container'>
            <div className='task-overview__actions'>
              <div className='task-overview__actions__header'>
                <a href='/vraag-hulp'>ik heb hulp nodig</a>
              </div>
              <div className='task-overview__actions__filter'>
                <div className='task-overview__actions__filter__postal-code'>
                  <input
                    id='postal-code'
                    name='postal-code'
                    type='text'
                    autoComplete='postal-code'
                    placeholder='1234AB'
                    value={postalCode}
                    className='extra-spacing'
                    onChange={(e) => {
                      const value = cleanPostalCode(e.target.value);
                      if (isValidPostalCode(value)) {
                        props.setPostalCodeRedux(value);
                      }
                      setPostalCode(value);
                    }}
                  />
                  {postalCode && (isValidPostalCode(postalCode)
                      ? <div className='form-segment--text__icon form-segment--text__icon--positive'><CheckmarkIcon/></div>
                      : <div className='form-segment--text__icon form-segment--text__icon--warning'><WarningIcon/></div>
                  )}
                </div>
                <div className='task-overview__actions__filter__type'>
                  <Select
                    value={selectedTaskType}
                    placeholder='type'
                    onChange={setSelectedTaskType}
                    className='select__container'
                    classNamePrefix='select'
                    isSearchable={false}
                    styles={selectStyles}
                    options={taskTypeOptions}
                  />
                </div>
                <div className='task-overview__actions__filter__when'>
                  <Select
                    value={selectedWhen}
                    placeholder='wanneer'
                    onChange={setSelectedWhen}
                    className='select__container'
                    classNamePrefix='select'
                    isSearchable={false}
                    styles={selectStyles}
                    options={whenOptions}
                  />
                </div>
              </div>
            </div>
            <div className='task-overview__task-list'>
              {loading && <Loader/>}
              {!loading && props.availableTasks && (
                props.availableTasks.length === 0
                  ? (
                    <>
                      <p>Wat goed dat je gewoon mensen wil helpen!</p>
                      <p>Dat hier geen aanvragen zijn betekent dat iedereen op ons platform geholpen is, maar niet dat er niemand in jouw omgeving is die heel erg hard hulp nodig heeft. Ons bereik is nog niet goed genoeg.</p>
                      <p>Wil jij echt verschil maken? Zorg dan dat óók deze hulpbehoevende mensen hulp kunnen vragen. <a href="/burenbriefje" target="_blank" rel="noopener noreferrar"><u>Hier kan je onze burenbrief en poster vinden</u></a>, als je deze afdrukt en door je wijk verspreidt zul jíj voor deze gewone mensen echt het verschil kunnen maken.</p>
                      <p>Samen helpen wij elkaar gewoon.</p>
                    </>
                  )
                  : props.availableTasks.map((availableTask) => {
                    let location = `${availableTask.zipcode} - ${availableTask.city}`;
                    let locationTooltip;
                    if (availableTask.distance){
                      let distance = `${availableTask.distance>1?availableTask.distance:'<1'}km`;
                      location = `${distance} - ${availableTask.city}`;
                      locationTooltip = availableTask.zipcode;
                    }

                    return (
                      <TaskCard
                        onMutate={() => fetchTasks()}
                        id={availableTask.id}
                        key={availableTask.id}
                        title={`${availableTask.TaskType.icon} ${availableTask.TaskType.name}`}
                        location={location}
                        locationTooltip={locationTooltip}
                        description={availableTask.description}
                        urgency={availableTask.when}
                        verified={availableTask.verified}
                        canClaim
                      />
                    )
                  }
                )
              )}
              {/* only show if no taskType is filtered on */}
              {!loading && (selectedTaskType.value == null) && props.doneTasks && props.doneTasks.length !== 0 && (
                <h3>Zojuist geholpen mensen</h3>
              )}
              {!loading && (selectedTaskType.value == null) && props.doneTasks && props.doneTasks.map((doneTask) => (
                <DoneTaskCard
                  id={doneTask.id}
                  key={doneTask.id}
                  title={`${doneTask.TaskType.name}`}
                  location={`${doneTask.zipcode} - ${doneTask.city}`}
                  doneAt={doneTask.doneAt}
                  confirmedAt={doneTask.confirmedAt}
                  doneBy={doneTask.doneBy}
                />
              ))}
            </div>
          </div>
          <div className='task-overview__illustration'>
            <div className='task-overview__illustration__content'>
              <h1>Hoe kan ik helpen?</h1>
              <ol>
                <li>Filter de hulpvraag</li>
                <li>Klik op 'ik help' bij een aanvraag waar je denkt te kunnen helpen.</li>
                <li>Neem contact op met het telefoonnummer dat verschijnt.</li>
                <li>Kun je toch niet helpen? Klik dan op 'zet terug'.</li>
                <li>Kun je hulp bieden? Super! Klik op 'afronden' om de hulpvraag definitief van de lijst te halen.</li>
              </ol>
              <h1>Bedankt voor je inzet!</h1>
              <TaskOverviewIllustration/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  taskTypes: state.tasks.taskTypes,
  availableTasks: state.tasks.availableTasks,
  doneTasks: state.tasks.doneTasks,
  postalCode: state.application.postalCode,
});

const mapDispatchToProps = {
  setPostalCodeRedux,
  getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskOverview);
