import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { api } from '../../utils'
import { messages } from '../../constants'
import { Header, Head } from '../../components'

function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const [sendMailSuccessfully, setSendMailSuccessfully] = useState(false);

  const forgotPassword = async (e) => {
    e.preventDefault();
    try {
      api.post('/auth/forgot', {email})
      toast.success(messages.sentEmailSuccessfully)
      setSendMailSuccessfully(true)
      return true
    } catch(e){
      toast.error(messages.somethingWentWrong)
      return false
    }
  };

  if (sendMailSuccessfully) {
    return (
      <Redirect to='/'/>
    )
  }

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='wachtwoord herstellen'/>
      <div className='layout layout--top layout--bottom'>
        <div className='container'>
          <h1>wachtwoord vergeten</h1>
          <form onSubmit={forgotPassword}>
            <div className='form-segment form-segment--text'>
              <label htmlFor='email'>email</label>
              <input
                id='email'
                name='email'
                type='text'
                autoComplete='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <input type='submit' value='verzenden'/>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  forgotPassword: state.auth.forgotPassword
});

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
