import { api } from '../../utils'

export const actions = {
	getBevrijdingsdag: '@@misc/stats/get'
}

export function getBevrijdingsdag() {
	return (dispatch) => {
		api.get('/events/bevrijdingsdag')
			.then((res) => {
				dispatch({
					type: actions.getBevrijdingsdag,
					data: res.data.data
				})
			})
			.catch((e) => console.log(e))
	}
}
