import React from 'react'

import { Header, Head } from '../components'

import './Terms.scss'

function Terms(props) {
  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='algemene voorwaarden'/>
      <div className='layout layout--bottom layout--top'>
        <div className='terms'>
          <span>Amsterdam 31 maart 2020</span>

          <h1>Algemene voorwaarden voor vragers van hulp op het platform</h1>

          <p>De uitbraak van het COVID-19 virus treft ons allen.<br/><br/>
          Met de website https://www.gewoonmensendiemensenwillenhelpen.nl/ wil Stichting Gewoonmensen het mensen die mensen willen helpen mogelijk maken dat te doen. Stichting Gewoonmensen doet dit, omdat zij het belangrijk vindt dat mensen er tijdens de coronacrisis voor elkaar zijn, kosteloos en zonder daarvoor iets terug te willen. Stichting Gewoonmensen vraagt niet meer dan dat de gebruikers van de website zich aan enkele heel logische basisregels houden. Deze regels zijn bedoeld om misbruik te voorkomen en het platform prettig en bruikbaar te houden voor alle gebruikers.<br/><br/>
          Voor de verschillende groepen gebruikers – particulieren die hulp aanbieden, particulieren die hulp vragen en organisaties die hulp vragen – gelden verschillende sets regels. De hieronder opgesomde regels, die onder meer gaan over de verantwoordelijkheid en aansprakelijkheid van Stichting Gewoonmensen, gelden voor <b>particulieren die hulp willen ontvangen.</b></p>

          <ol>
            <li>
              <span className='article-title'>TOEPASSINGSGEBIED</span>
              <ol>
                <li>
                  <span>Deze algemene voorwaarden gelden voor particulieren die hulp willen ontvangen ("<b>Hulpvragers</b>") via het door Stichting Gewoonmensen, op de internetpagina www.gewoonmensendiemensenwillenhelpen.nl beschikbaar gestelde platform (het "<b>Platform</b>") waar Hulpvragers in contact kunnen komen met particulieren die hulp aanbieden ("<b>Hulpaanbieders</b>").</span>
                </li>
                <li>
                  <span>Alvorens gebruik te maken van het Platform, moet de Hulpvrager een verzoek aanmaken, dat ten minste bestaat uit een omschrijving van de aard van de verzochte hulp, hun postcode en het tijdstip waarop de Hulpvrager de hulp nodig heeft (de "<b>Hulpvraag</b>"). Bij deze registratie accepteert de Hulpvrager de algemene voorwaarden. Stichting Gewoonmensen bevestigt de Hulpvraag aan de Hulpvrager per e-mail. Hiermee komt de gebruikersovereenkomst met Stichting Gewoonmensen tot stand, waarop deze algemene voorwaarden van toepassing zijn.</span>
                </li>
                <li>
                  <span>De Hulpvrager kan alleen een particulier zijn die zichzelf heeft ingeschreven. Het is particulieren of organisaties uitdrukkelijk niet toegestaan zich in te schrijven als Hulpvrager om hulp of vrijwilligerswerk te zoeken voor anderen.</span>
                </li>
                <li>
                  <span>Het is niet mogelijk actief te zijn op het Platform zonder gebonden te zijn aan de algemene voorwaarden. Afwijkingen van de algemene voorwaarden zijn niet mogelijk zonder de uitdrukkelijke schriftelijke toestemming van Stichting Gewoonmensen.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>DOEL VAN HET PLATFORM</span>
              <ol>
                <li>
                  <span>Het doel van het Platform is ervoor te zorgen dat Hulpaanbieders, Hulpvragers en Organisaties op een efficiënte manier met elkaar in contact kunnen komen. Daarvoor plaatsen Hulpvragers hun Hulpvraag op het Platform. Hulpaanbieders kunnen naar aanleiding van een verzoek contact opnemen met de Hulpvrager.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen biedt Hulpaanbieders slechts de mogelijkheid contact met Hulpvragers op te nemen. Stichting Gewoonmensen is niet op de hoogte van de afspraken die zij daarna onderling maken over het verlenen en ontvangen van hulp of van de manier waarop en de voorwaarden waaronder dat gebeurt.</span>
                </li>
                <li>
                  <span>tichting Gewoonmensen biedt, behalve door het ter beschikking stellen van het Platform, zelf geen hulp en is geen partij bij overeenkomsten die Hulpaanbieders sluiten met Organisaties of Hulpvragers.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>VOORWAARDEN: LEEFTIJD, VRIJWILLIGERSWERK, NALEVEN WET</span>
              <ol>
                <li>
                  <span>Om Hulpvrager te zijn, moet je ouder zijn dan 18. </span>
                </li>
                <li>
                  <span>Het is Hulpvragers <b>niet</b> toegestaan het Platform te gebruiken om mensen te vinden die betaald werk willen verrichten. Het is Hulpaanbieders daarom verboden loon of een vergoeding voor hun werkzaamheden vragen. Het is de Hulpaanbieder ook verboden aanspraak te maken op kostenvergoeding, tenzij dat vooraf is afgesproken met de Hulpvrager. Het is Hulpvragers verboden loon of een vergoeding voor werkzaamheden aan te bieden.</span>
                </li>
                <li>
                  <span>Hoewel Stichting Gewoonmensen enkel partijen in contact brengt via het platform, gaat Stichting Gewoonmensen ervan uit dat zowel Hulpvragers als Hulpaanbieders zich bij het gebruik van het Platform zich aan de wet te houden. Enkele regels die Stichting Gewoonmensen bijzonder belangrijk vindt, zijn de richtlijnen van het RIVM, het verbod op discriminatie, het verbod op fraude, diefstal, oplichting, mishandeling en overige strafbare feiten.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>VERPLICHTINGEN HULPVRAGER</span>
              <ol>
                <li>
                  <span>De Hulpvraag is persoonsgebonden. Het is de Hulpvrager verboden anderen toegang te verlenen tot zijn of haar Hulpvraag of dat te delen.</span>
                </li>
                <li>
                  <span>Het is verboden Hulpaanbieders te benaderen met een ander doel dan het ontvangen van de in de Hulpvraag gevraagde hulp. Acquisitie of commerciële activiteiten zijn nadrukkelijk verboden, net als het doen van voorstellen die in strijd zijn met de goede zeden of de openbare orde.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>VERPLICHTINGEN STICHTING GEWOONMENSEN</span>
              <ol>
                <li>
                  <span>Stichting Gewoonmensen doet, voor zover dit technisch mogelijk is, haar best ervoor te zorgen dat het Platform blijft werken en om verbindingsonderbrekingen of verstoringen van het gebruik te voorkomen. Stichting Gewoonmensen kan hiervoor tijdelijk de toegang tot het Platform beperken.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen beheert, zolang de Hulpvrager actief is op het Platform, een persoonlijke Hulpvraag voor de Hulpvrager.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen is niet verplicht ervoor te zorgen dat een Hulpvrager daadwerkelijk de gevraagde hulp krijgt.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>AANVANG EN BEËINDIGING</span>
              <ol>
                <li>
                  <span>De gebruikersovereenkomst tussen de Hulpvrager en Stichting Gewoonmensen komt tot stand op het moment dat de Hulpvrager zijn Hulpvraag aanmaakt, de Hulpvrager deze algemene voorwaarden accepteert en Stichting Gewoonmensen de Hulpvraag van de Hulpvrager op het Platform plaatst.</span>
                </li>
                <li>
                  <span>Als de Hulpvrager geen hulp meer nodig heeft, meldt hij of zij de Hulpvraag zo spoedig mogelijk af.</span>
                </li>
                <li>
                  <span>De gebruikersovereenkomst eindigt op het moment dat de Hulpvrager en/of Stichting Gewoonmensen zijn/haar Hulpvraag verwijdert.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen heeft het recht de Hulpvraag te verwijderen indien daar gegronde redenen voor bestaan. In dat geval eindigt ook de gebruikersovereenkomst.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>JUISTHEID GEGEVENS EN GEGEVENSBESCHERMING</span>
              <ol>
                <li>
                  <span>De Hulpvrager is verplicht bij de registratie de juiste gegevens te verstrekken.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen verwerkt persoonsgegevens in overeenstemming met haar Privacy verklaring. </span>
                </li>
              </ol>
            </li>

            <li>
              <span className='article-title'>VERANTWOORDELIJKHEID EN AANSPRAKELIJKHEID</span>
              <ol>
                <li>
                  <span>Stichting Gewoonmensen is een zeer jonge vrijwilligersorganisatie die het beste met de gebruikers van het Platform voorheeft, maar hun veiligheid niet kan garanderen. Stichting Gewoonmensen controleert Hulpvragen <b>NIET</b> op juistheid of volledigheid. Stichting Gewoonmensen controleert de identiteit van Hulpaanbieders, Hulpvragers en Organisaties niet. Hulpvragers dienen hierop bedacht te zijn en adequate voorzorgsmaatregelen te treffen.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen controleert Hulpaanbieders, Hulpvragers en Organisaties niet op hun gezondheidstoestand en is in het bijzonder niet op de hoogte van enige mogelijke besmetting met COVID-19. Hulpvragers dienen hierop bedacht te zijn en adequate voorzorgsmaatregelen te treffen.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen controleert Hulpaanbieders, Hulpvragers en Organisaties niet op antecedenten. Hulpvragers dienen hierop bedacht te zijn en adequate voorzorgsmaatregelen te treffen.</span>
                </li>
                <li>
                  <span>Het is de verantwoordelijkheid van Hulpaanbieders, Hulpvragers en Organisaties dat zij handelen overeenkomstig de bepalingen van de Nederlandse wet en elkaar en elkaars bezit met respect behandelen. Stichting Gewoonmensen kan hier geen invloed op uitoefenen en kan derhalve niet aansprakelijk worden gesteld voor wangedrag van Hulpaanbieders, Hulpvragers en/of Organisaties. Indien een Hulpaanbieder, Hulpvrager of een Organisatie zich misdraagt, wordt Stichting Gewoonmensen hier onverwijld van op de hoogte gebracht.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen doet haar best om personen die zich schuldig maken aan discriminatie of andere onrechtmatige gedragingen na een melding van het Platform te verwijderen maar kan niet garanderen dat zij van wangedrag op de hoogte is of dat dat altijd lukt. Stichting Gewoonmensen is niet verantwoordelijk voor de inhoud van Hulpvragen en/of teksten op het Platform. Stichting Gewoonmensen aanvaardt <b>geen enkele aansprakelijkheid</b> voor schade, van welke aard dan ook, voor wat betreft de hulpverlening door een Hulpaanbieder en ook niet voor enige verdere schade die het gevolg kan zijn van het plaatsen van een Hulpvraag of schade, van welke aard ook, die voortvloeit uit het gebruik van het Platform.</span>
                </li>
              </ol>
            </li>

            <li>
              <span className='article-title'>OVERIGE</span>
              <ol>
                <li>
                  <span>Deze algemene voorwaarden bevatten de volledige contractuele verhouding tussen Stichting Gewoonmensen en de Hulpvrager. Aanvullende afspraken kunnen alleen schriftelijk tot stand komen.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen is te allen tijde gerechtigd deze algemene voorwaarden te wijzigen met inachtneming van een termijn van twee werkdagen. Hulpaanbieders worden hiervan op de hoogte gesteld via het bij aanmelding opgegeven e-mailadres.</span>
                </li>
                <li>
                  <span>Op de overeenkomst tussen Stichting Gewoonmensen en de Hulpvrager is Nederlands recht van toepassing. Alleen de Rechtbank Amsterdam is in eerste aanleg bevoegd kennis te nemen van geschillen die hieruit voortvloeien.</span>
                </li>
              </ol>
            </li>
          </ol>

          <br/>
          <br/>
          <hr/>
          <br/>
          <br/>
          <h1>Algemene voorwaarden voor aanbieders van hulp op het platform</h1>

          <p>De uitbraak van het COVID-19 virus treft ons allen.<br/><br/>
          Met de website https://www.gewoonmensendiemensenwillenhelpen.nl/ wil StichtingGewoonmensen het mensen die mensen willen helpen mogelijk maken dat te doen. StichtingGewoonmensen doet dit, omdat zij het belangrijk vindt dat mensen er tijdens de coronacrisis voorelkaar zijn, kosteloos en zonder daarvoor iets terug te willen. Stichting Gewoonmensen vraagt nietmeer dan dat de gebruikers van de website zich aan enkele heel logische basisregels houden. Dezeregels zijn bedoeld om misbruik te voorkomen en het platform prettig en bruikbaar te houden vooralle gebruikers.<br/><br/>
          Voor de verschillende groepen gebruikers – particulieren die hulp aanbieden, particulieren die hulpvragen en organisaties die hulp vragen – gelden verschillende sets regels. De hieronder opgesomderegels, die onder meer gaan over de verantwoordelijkheid en aansprakelijkheid van StichtingGewoonmensen, gelden voor <b>particulieren die hulp willen aanbieden.</b></p>

          <ol>
            <li>
              <span className='article-title'>TOEPASSINGSGEBIED</span>
              <ol>
                <li>
                  <span>Deze algemene voorwaarden gelden voor particulieren die hulp willen aanbieden ("<b>Hulpaanbieders</b>") via het door Stichting Gewoonmensen, op de internetpagina www.gewoonmensendiemensenwillenhelpen.nl beschikbaar gestelde platform (het "<b>Platform</b>") waar Hulpaanbieders in contact kunnen komen met particulieren die hulpvragen ("<b>Hulpvragers</b>") en organisaties die hulp vragen ("<b>Organisaties</b>").</span>
                </li>
                <li>
                  <span>Alvorens gebruik te maken van het Platform, moet de Hulpaanbieder zich registreren. Bij deze registratie accepteert de Hulpaanbieder de algemene voorwaarden. StichtingGewoonmensen bevestigt de aanmelding aan de Hulpaanbieder per e-mail. Hiermee komtde gebruikersovereenkomst met Stichting Gewoonmensen tot stand, waarop deze algemene voorwaarden van toepassing zijn.</span>
                </li>
                <li>
                  <span>De Hulpaanbieder kan alleen een particulier zijn die zichzelf heeft ingeschreven. Het isparticulieren of organisaties uitdrukkelijk niet toegestaan zich in te schrijven als vrijwilligerom vrijwilligerswerk te zoeken voor anderen.</span>
                </li>
                <li>
                  <span>Het is niet mogelijk actief te zijn op het Platform zonder gebonden te zijn aan de algemenevoorwaarden. Afwijkingen van de algemene voorwaarden zijn niet mogelijk zonder deuitdrukkelijke schriftelijke toestemming van Stichting Gewoonmensen.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>DOEL VAN HET PLATFORM</span>
              <ol>
                <li>
                  <span>Het doel van het Platform is ervoor te zorgen dat Hulpaanbieders, Hulpvragers enOrganisaties op een efficiënte manier met elkaar in contact kunnen komen. Daarvoorplaatsen Hulpvragers een verzoek om hulp, dat ten minste bestaat uit een omschrijving vande aard van de verzochte hulp, hun postcode en het tijdstip waarop de Hulpvrager de hulpnodig heeft (de "<b>Hulpvraag</b>"), op het Platform. Hulpaanbieders kunnen naar aanleiding vaneen verzoek van een Hulpvrager contact opnemen met de betreffende Hulpvrager.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen biedt Hulpaanbieders, Hulpvragers en Organisaties slechts demogelijkheid met elkaar contact op te nemen. Stichting Gewoonmensen is niet op de hoogtevan de afspraken die zij daarna onderling maken over het verlenen en ontvangen van hulpof van de manier waarop en de voorwaarden waaronder dat gebeurt.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen biedt, behalve door het ter beschikking stellen van het Platform, zelf geen hulp en is geen partij bij overeenkomsten die Hulpaanbieders sluiten metOrganisaties of Hulpvragers.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>VOORWAARDEN: LEEFTIJD, VRIJWILLIGERSWERK, NALEVEN WET</span>
              <ol>
                <li>
                  <span>Om Hulpaanbieder te zijn, moet je ouder zijn dan 18.</span>
                </li>
                <li>
                  <span>Het is Hulpaanbieders <b>niet</b> toegestaan het Platform te gebruiken om betaald werk teverrichten. Hulpaanbieders zullen geen loon of vergoeding voor hun werkzaamheden vragen. Het is de Hulpaanbieder verboden aanspraak te maken op kostenvergoeding, tenzijdat vooraf is afgesproken met de Hulpvrager. Het is Hulpvragers verboden loon of eenvergoeding voor werkzaamheden aan te bieden.</span>
                </li>
                <li>
                  <span>Hoewel Stichting Gewoonmensen enkel partijen in contact brengt via het Platform, gaat Stichting Gewoonmensen ervan uit dat zowel Hulpvragers als Hulpaanbieders zich bij hetgebruik van het Platform en de verdere activiteiten jegens elkaar, zich aan de wet te houden.Enkele regels die Stichting Gewoonmensen bijzonder belangrijk vindt, zijn de richtlijnenvan het RIVM, het verbod op discriminatie, het verbod op fraude, diefstal, mishandeling enoverige strafbare feiten.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>VERPLICHTINGEN HULPAANBIEDER</span>
              <ol>
                <li>
                  <span>Het account van de Hulpaanbieder is persoonsgebonden. Het is de Hulpaanbieder verbodenanderen toegang te verlenen tot zijn of haar account of dat te delen.</span>
                </li>
                <li>
                  <span>Het is verboden Hulpvragers of Organisaties te benaderen met een ander doel dan hetbieden van de in de Hulpvraag gevraagde hulp. Acquisitie of commerciële activiteiten zijnnadrukkelijk verboden, net als het doen van voorstellen die in strijd zijn met de goede zedenof de openbare orde.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>VERPLICHTINGEN STICHTING GEWOONMENSEN</span>
              <ol>
                <li>
                  <span>Stichting Gewoonmensen doet, voor zover dit technisch mogelijk is, haar best ervoor te zorgen dat het Platform blijft werken en om verbindingsonderbrekingen of verstoringen van het gebruik te voorkomen. Stichting Gewoonmensen kan hiervoor tijdelijk de toegang tot het Platform beperken.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen beheert, zolang de Hulpaanbieder actief is op het Platform, een persoonlijk account voor de Hulpaanbieder.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen is niet verplicht ervoor te zorgen dat een Hulpaanbieder daadwerkelijk vrijwilligerswerk kan verrichten.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>AANVANG EN BEËINDIGING</span>
              <ol>
                <li>
                  <span>De gebruikersovereenkomst tussen de Hulpaanbieder en de Stichting Gewoonmensen komttot stand op het moment dat de Hulpaanbieder zich heeft geregistreerd, de Hulpaanbiederdeze algemene voorwaarden heeft geaccepteerd en de Stichting Gewoonmensen deaanmelding van de Hulpaanbieder heeft bevestigd.</span>
                </li>
                <li>
                  <span>Als de Hulpaanbieder wil stoppen met vrijwilligerswerk, meldt hij of zij dit zo spoedig mogelijk bij Stichting Gewoonmensen.</span>
                </li>
                <li>
                  <span>De gebruikersovereenkomst eindigt op het moment dat de Hulpaanbieder en/of StichtingGewoonmensen zijn/haar persoonsgebonden account verwijdert.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen heeft het recht het account van de Hulpaanbieder te verwijderen indien daar gegronde redenen voor bestaan. In dat geval eindigt ook de gebruikersovereenkomst.</span>
                </li>
              </ol>
            </li>
            <li>
              <span className='article-title'>JUISTHEID GEGEVENS EN GEGEVENSBESCHERMING</span>
              <ol>
                <li>
                  <span>De Hulpaanbieder is verplicht bij de registratie de juiste gegevens te verstrekken.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen verwerkt persoonsgegevens in overeenstemming met haar Privacy verklaring.</span>
                </li>
              </ol>
            </li>

            <li>
              <span className='article-title'>VERANTWOORDELIJKHEID EN AANSPRAKELIJKHEID</span>
              <ol>
                <li>
                  <span>Stichting Gewoonmensen is een zeer jonge vrijwilligersorganisatie die het beste met de gebruikers van het Platform voorheeft, maar hun veiligheid niet kan garanderen. Stichting Gewoonmensen controleert Hulpvragen <b>NIET</b> op juistheid of volledigheid. Stichting Gewoonmensen controleert Hulpaanbieders, Hulpvragers en Organisaties niet op hun gezondheidstoestand en is in het bijzonder niet op de hoogte van enige mogelijke besmetting met COVID-19. Hulpaanbieders dienen hierop bedacht te zijn en adequate voorzorgsmaatregelen te treffen.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen controleert Hulpaanbieders, Hulpvragers en Organisaties niet op antecedenten. Hulpaanbieders dienen hierop bedacht te zijn en adequate voorzorgsmaatregelen te treffen.</span>
                </li>
                <li>
                  <span>Het is de verantwoordelijkheid van Hulpaanbieders, Hulpvragers en Organisaties dat zij handelen overeenkomstig de bepalingen van de Nederlandse wet en elkaar en elkaars bezit met respect behandelen. Stichting Gewoonmensen kan hier geen invloed op uitoefenen en kan derhalve niet aansprakelijk worden gesteld voor wangedrag van Hulpaanbieders, Hulpvragers en/of Organisaties. Indien een Hulpaanbieder, Hulpvrager of een Organisatie zich misdraagt, wordt Stichting Gewoonmensen hier onverwijld van op de hoogte gebracht.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen doet haar best om discriminatoire of overduidelijk onethische of onrechtmatige Hulpvragen te verwijderen maar kan niet garanderen dat dat altijd lukt. Stichting Gewoonmensen is niet verantwoordelijk voor de inhoud van Hulpvragen en/of teksten op het Platform.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen aanvaardt <b>geen enkele aansprakelijkheid voor schade</b>, van welke aard dan ook, voor wat betreft de Hulpvragen en ook niet voor enige verdere schade die het gevolg kan zijn van het reageren op een Hulpvraag of schade, van welke aard ook, die voortvloeit uit het gebruik van het Platform.</span>
                </li>
              </ol>
            </li>

            <li>
              <span className='article-title'>OVERIGE</span>
              <ol>
                <li>
                  <span>Deze algemene voorwaarden bevatten de volledige contractuele verhouding tussen Stichting Gewoonmensen en de Hulpaanbieder. Aanvullende afspraken kunnen alleen schriftelijk tot stand komen.</span>
                </li>
                <li>
                  <span>Stichting Gewoonmensen is te allen tijde gerechtigd deze algemene voorwaarden te wijzigen met inachtneming van een termijn van twee werkdagen. Hulpaanbieders worden hiervan op de hoogte gesteld via het bij aanmelding opgegeven e-mailadres.</span>
                </li>
                <li>
                  <span>Op de overeenkomst tussen Stichting Gewoonmensen en de Hulpaanbieder is Nederlands recht van toepassing. Alleen de Rechtbank Amsterdam is in eerste aanleg bevoegd kennis te nemen van geschillen die hieruit voortvloeien.</span>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}

export default Terms;
