import { actions } from './actions'

const initialState = {
  user: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.login:
    case actions.signup:
    case actions.resetPassword:
    case actions.getProfile:
      return reduceUser(state, action)
    default:
      return state
  }
}

const reduceUser = (state, action) => ({
  ...state,
  user: action.data,
})
