import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { api } from '../utils';
import { Head } from '../components';
import { toast } from 'react-toastify'
import { messages } from '../constants'
import './ConfirmClaimTask.scss';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function ConfirmClaimTask(props) {
  const [confirmClaimed, setConfirmClaimed] = useState(false);
  const [popup, setPopup] = useState(true);

  useEffect(() => {
    confirmAlert({
      title: 'Bevestig dat je helpt',
      message: 'Tof dat je wil helpen! Zodra je op “ja” klikt kan niemand anders dit hulpverzoek meer zien. Doe dat dus alleen als je 100% zeker bent dat je wil en kan helpen. Probeer dan binnen 1 uur contact op te nemen.',
      buttons: [
        {
          label: 'Ja! Ik help!',
          onClick: () => confirmClaim()
        },
        {
          label: 'Nee',
          onClick: () => setPopup(false)
        }
      ]
    });
  }, []);

  const confirmClaim = async () => {
    setPopup(false);
    try {
      await api.post(`/task/${props.match.params.uuid}/status/update/${props.match.params.hash}/${props.match.params.id}`, { newStatus: 'claimed' });
      setConfirmClaimed(true)
      toast.success(messages.taskClaimed)
    } catch (e) {
      if(e.response.data.error === 'Task is already done, confirmed or deleted.'){
        toast.error(messages.taskAlreadyClaimed)
        return false;
      }
      toast.error(e.response.data.error || messages.somethingWentWrong)
    }
  }

  return (
    <>
    <Head metaPage={props.metaPage} />
    <div className='confirmclaim container'>
      <h1>{confirmClaimed && !popup ? 'Yes!' : 'Bezig met bevestigen...'}</h1>
        {(confirmClaimed && !popup && !props.user) ? (
          <div>
            <h2>Log nu in om direct contact op te nemen</h2>
            <div className='confirmclaim__button--container'>
              <Link className='confirmclaim__button__login' to='/login'>login</Link>
            </div>
          </div>
        ) :
          (confirmClaimed && !popup && props.user) ? (<Redirect to='/profiel'/>) : (<p>Keer terug naar <Link to='/'>home</Link></p>)
        }
    </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, null)(ConfirmClaimTask);
