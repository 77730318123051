import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { api } from '../utils'
import { messages } from '../constants'
import { Header, Head } from '../components'

function Unsubscribe(props) {
  const [unsubscribed, setUnsubscribed] = useState(false);

  useEffect(() => {
    if (!unsubscribed) {
      const deleteTask = async () => {
        try {
          await api.post(`/auth/profile/${props.match.params.id}/${props.match.params.hash}`, { proximityDigestSubscribe: false });
          setUnsubscribed(true)
          toast.success(messages.subscribeToggleSuccessfully)
        } catch (e) {
          toast.error(e.response.data.error || messages.somethingWentWrong)
        }
      };
      deleteTask()
    }
  }, [unsubscribed, props.match.params.id, props.match.params.hash]);

  if (unsubscribed) return (<Redirect to='/'/>)

  return (
    <>
      <Head metaPage={props.metaPage} />
      <Header title='Bezig met afmelden...'/>
      <div className='layout layout--bottom layout--top'>
        <div className='container'>
          <a href='/'>Keer terug naar de homepagina</a>
        </div>
      </div>
    </>
  );
}

export default Unsubscribe;
