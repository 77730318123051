export const cleanPostalCode = (str) => {
  return [
    str.charAt(0).match(/[0-9]/g) || '',
    str.charAt(1).match(/[0-9]/g) || '',
    str.charAt(2).match(/[0-9]/g) || '',
    str.charAt(3).match(/[0-9]/g) || '',

    str.charAt(4).match(/[A-z]/g) || '',
    str.charAt(5).match(/[A-z]/g) || ''
  ].join('').toUpperCase()
};
