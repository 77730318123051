import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Head } from '../components'

import { ReactComponent as Wave} from '../assets/wave.svg'
import { Stats, InstagramFeed, Intro } from './lib'

import './Dashboard.scss'

function Dashboard(props) {
  const [visible, isVisible] = useState(false);

  const toggleVisibility = () => {
    isVisible(visible => !visible)
  }

  return (
    <>
      <Head metaPage={props.metaPage} />
      <div className='dashboard__container'>
        <div className='layout dashboard'>
					<div className='dashboard__cta-container'>
							<h2>Lieve gewone mensen,</h2>
<p>
Door het overweldigende succes van Gewoon Mensen Die Mensen Willen Helpen hebben vele gewone mensen hulp kunnen krijgen in tijden van nood.
<br/>
Nu de intelligente lockdown regels versoepeld worden, zijn ook de nood-hulpvragen af aan het nemen. Nu lijkt ons online prikbord overbodig en hebben wij besloten hem voor nu offline te halen.
<br/>Wellicht komt hij weer online als we merken dat de nood hoog is, maar voor nu lijkt dit niet het geval. 
Natuurlijk zijn er altijd mensen die hulp kunnen gebruiken dus als je wil helpen of hulp nodig hebt zou jij je kunnen aanmelden bij Corona Helpers: <a href="https://www.coronahelpers.nl/" target="_blank">https://www.coronahelpers.nl/</a>
<br/>
<br/>
Veel sterkte nog deze tijd & blijf gezond!
<br/>
Liefs,
<br/>
Het team van gewoon mensen die mensen willen helpen.
</p>
					</div>
        </div>
        <div className='dashboard__container__wave'>
          <Wave/>
        </div>
      </div>
	<div className='layout layout--top'>
		<Intro/>
	</div>
	<div className='layout layout--top layout--bottom'></div>
    </>
  );
}

const mapStateToProps = state => ({
  postalCode: state.application.postalCode
});

export default connect(mapStateToProps)(Dashboard);
